import { requester } from "App";
import { mobileNoFormat } from "InitialData/regExp";
import Dummy from "components/Dummy";
import P from "components/P";
import AccordionText from "components/accordion/AccordionText";
import AccordionWrapper from "components/accordion/AccordionWrapper";
import CustomButton from "components/buttons/CustomButton";
import InputText from "components/inputs/signUp/InputText";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { AuthContext } from "providers/AuthProvider";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "classnames";
import puffuLogo from "resources/img/puffu/puffuIcon.png";
import PolicyPage from "routes/front/mobile/policy/PolicyPage";
import { AuthReducer } from "shared/redux/reducers/auth/AuthReducer";
import { validateInputs } from "shared/utils/Utils";
import style from "./MobileNumberSignIn.module.css";
import PrivacyPolicy from "routes/front/mobile/policy/PrivacyPolicy";



function MobileNumberSignIn(props) {
    const { isMobile } = useContext(BrowserDetectContext);
    const inputs = useRef([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onMemberAccessClick = () => {
        if (isChecked) {
            validateInputs(inputs.current).then((isValid) => {

                if (isValid.isValid) {
                    if (inputs.current[0].getValue() === "01088888888") {
                        let data = { storeId: "root", userName: inputs.current[0].getValue(), role: "MEMBER" };
                        requester.userNumberSignIn(data, (result) => {

                            if (result.code === -1) {
                                if (result.message === "Invalid userName") {
                                    //존자하지 않는 아이디
                                    toast.error(t("noUserName"), {
                                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                                    });
                                } else if (result.message === "Invalid credentials") {
                                    //비번틀림
                                    toast.error(t("noPassword"), {
                                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                                    });
                                } else {
                                    toast.error(t("잘못된 연락처"), {
                                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                                    });
                                }
                            } else if (result.code === 0) {
                                requester.useYnConfirm(data, (useYn) => {
                                    
                                    let isWithdrawn = useYn.data;
                                    if (isWithdrawn) {
                                        toast.error(t("탈퇴된 회원입니다."), {
                                            autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                                        });
                                    } else {

                                        let tokenData = result.data.token;
                                        dispatch(AuthReducer.actions.setToken(tokenData));
                                        navigate(`/ws/${props.storeCode}/mobile/main`)
                                    }
                                })
                            }
                        })
                    } else {
                        navigate("/memberAccess", { state: { storeCode: props.storeCode, mobileNumber: inputs.current[0].getValue() } });
                    }
                } else {
                    toast.error(t("올바른 연락처를 입력해주세요"), {
                        autoClose: 1000, position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        } else {
            toast.error("약관에 동의해주세요", {
                autoClose: 1000, position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const accordionStyle = {
        '--cui-accordion-bg': "var(--bg-color)",
        '--cui-accordion-active-color': "white",
        '--cui-accordion-border-color': "white",
        '--cui-accordion-color': "black",
        '--cui-accordion-active-bg': "var(--bg-color)",
        '--cui-accordion-btn-focus-border-color': "none",
        '--cui-accordion-btn-focus-box-shadow': "none",
        '--cui-accordion-btn-color': "white"
    }
    const btnStyle = {
        backgroundColor: "var(--main-color)",
        border: "none",
        width: "90%",
        // borderRadius: "35px",
        height: "55px",
        fontWeight: 900,
        color: "black"
    }


    return (
        <Container>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "var(--bg-color)" }}>
                <FlexChild alignItems={"center"} justifyContent={"center"} height={"90vh"} >
                    <VerticalFlex gap={30} >
                        <FlexChild justifyContent={"flex-end"}>
                            <Dummy height={"50px"} />
                            <img src={puffuLogo} width={"15%"} />
                            <Dummy height={"30px"} />
                        </FlexChild>
                        <FlexChild height={"max-content"} width={"90%"}>
                            <VerticalFlex gap={10}>
                                <FlexChild height={"max-content"}>
                                    {/* <AccordionWrapper active={[1]} alwaysOpen={true} style={accordionStyle}>
                                        <AccordionText index={1} alwaysOpen={true} height={180} header={"이용약관"} > */}
                                    <div style={{ height: "180px", overflow: "scroll", border: "1px solid white" }}>
                                        <PolicyPage signIn={true} />
                                    </div>
                                    <Dummy height={"30px"} />
                                    <div style={{ height: "180px", overflow: "scroll", border: "1px solid white" }}>
                                        <PrivacyPolicy signIn={true} />
                                    </div>
                                    {/* </AccordionText>
                                    </AccordionWrapper> */}
                                </FlexChild>
                                <FlexChild height={"max-content"}>
                                    <HorizontalFlex gap={15}>
                                        <FlexChild width={"max-content"}>
                                            <Center width={"100%"} textAlign={"left"} height={"max-content"}>
                                                <P color={"white"}>이용약관, 개인정보 수집 활용,</P>
                                                <P color={"white"}>고유식별처리정보활용에 동의합니다.</P>
                                            </Center>
                                        </FlexChild>
                                        <FlexChild>
                                            <input
                                                type="checkbox"
                                                id="agreement"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                style={{ display: "none" }}
                                            />
                                            <label htmlFor="agreement" className={clsx(style.checkmark1, { [style.animated]: isChecked })}></label>
                                        </FlexChild>
                                    </HorizontalFlex>

                                </FlexChild>
                            </VerticalFlex>
                        </FlexChild>
                        <FlexChild width={"90%"} height={"max-content"} >
                            <InputText regExp={[mobileNoFormat]} mobileText ref={el => (inputs.current[0] = el)} placeHolder={"휴대폰번호"} number />
                        </FlexChild>
                        <FlexChild >
                            <CustomButton text={"로그인 & 회원가입"} style={btnStyle} onClick={onMemberAccessClick} />
                        </FlexChild>
                    </VerticalFlex>
                </FlexChild>
            </div>
        </Container>
    );
}

export default MobileNumberSignIn;