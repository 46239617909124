
import P from "components/P";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import giftBoxImg from "resources/img/puffu/giftBox.svg";
import colorQrImg from "resources/img/puffu/colorQr.svg";
import puffuIcon from "resources/img/puffu/puffuIcon.svg";

import style from "./PaymentLanding.module.css";
import Inline from "layouts/container/Inline";
import Container from "layouts/container/Container";

function PaymentLanding(props) {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState();


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryData = queryParams.get('data');
        if (location.state) {
            setData(location.state.data);
        } else if (queryData) {
            const jsonData = JSON.parse(queryData);
            setData(jsonData);
        }
    }, [location])
    const paymentClick = () => {
        navigate("/memberAccess", { state: { paymentData: data, type: "payment", storeCode: data.storeCode } })
    }


    return (
        <Container height={"80vh"} backgroundColor={"var(--bg-color)"}>
            <VerticalFlex gap={30}>
                <FlexChild />
                <FlexChild height={"max-content"}>
                    <img src={puffuIcon} width={"30%"} />
                </FlexChild>
                <FlexChild height={"max-content"}>
                    <P color={"white"} size={"5vw"} weight={800}>결제 방법을 선택해주세요</P>
                </FlexChild>
                <FlexChild >
                    <VerticalFlex gap={10}>

                        <FlexChild>
                            <div className={style.boxArea} onClick={paymentClick} style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}>
                                <HorizontalFlex backgroundColor={"rgba(248, 248, 248, 1)"}>
                                    <FlexChild width={"40%"} backgroundColor={"rgba(248, 248, 248, 1)"}>
                                        <img src={colorQrImg} width={"50%"} style={{ filter: "brightness(0)" }} />
                                    </FlexChild>
                                    <FlexChild backgroundColor={"rgba(248, 248, 248, 1)"}>
                                        <VerticalFlex flexStart backgroundColor={"rgba(248, 248, 248, 1)"}>
                                            <FlexChild backgroundColor={"rgba(248, 248, 248, 1)"}>
                                                <P size={"5vw"} weight={800}>즉시 결제</P>
                                            </FlexChild>
                                            <FlexChild backgroundColor={"rgba(248, 248, 248, 1)"}>
                                                <P size={"4.2vw"} weight={600}>본인인증 후</P>
                                            </FlexChild>
                                            <FlexChild backgroundColor={"rgba(248, 248, 248, 1)"}>
                                                <Inline backgroundColor={"rgba(248, 248, 248, 1)"}>
                                                    <P size={"4.2vw"} weight={700} color={"var(--point-color)"}>간편하게 </P>
                                                    <P size={"4.2vw"} weight={600}>결제하기</P>
                                                </Inline>
                                            </FlexChild>
                                        </VerticalFlex>
                                    </FlexChild>
                                </HorizontalFlex>
                            </div>
                        </FlexChild>
                    </VerticalFlex>
                </FlexChild>
                <FlexChild />
            </VerticalFlex>
        </Container>
    );
}

export default PaymentLanding;