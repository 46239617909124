import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalMiddleWrapper from "layouts/wrapper/VerticalMiddleWrapper";
import style from "./ModalBase.module.css"
import { useEffect } from "react";
import VerticalFlex from "layouts/flex/VerticalFlex";
import SquareWrapper from "layouts/wrapper/SquareWrapper";
import P from "components/P";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faTimes } from "@fortawesome/free-solid-svg-icons";
import Center from "layouts/wrapper/Center";


function ModalBaseHeader({ title, modalClose, headerStyle }) {
    return (
        <div className={style.header} style={headerStyle}>
            <HorizontalFlex>
                <FlexChild width={50}>

                </FlexChild>
                <FlexChild>
                    <VerticalMiddleWrapper>
                        <p>{title}</p>
                    </VerticalMiddleWrapper>
                </FlexChild>
                <FlexChild width={50} height={"100%"}>
                    <VerticalFlex>
                        <FlexChild padding={10}>
                            <SquareWrapper>
                                <div style={{ width: "100%", height: "100%", borderRadius: "100%", backgroundColor: "#5D5D5D" }} onClick={modalClose}>
                                    <Center>
                                        <FontAwesomeIcon icon={fas["faTimes"]} color={"white"} />
                                    </Center>
                                </div>
                            </SquareWrapper>
                        </FlexChild>
                    </VerticalFlex>
                </FlexChild>
                <FlexChild width={10}></FlexChild>
            </HorizontalFlex>
        </div>
    );
}

export default ModalBaseHeader;