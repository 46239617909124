import NiceModal from "@ebay/nice-modal-react";
import { requester } from "App";
import P from "components/P";
import CustomButton from "components/buttons/CustomButton";
import FlexChild from "layouts/flex/FlexChild";

import VerticalFlex from "layouts/flex/VerticalFlex";
import ModalBase from "modals/base/ModalBase";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { HOST } from "routes/front/main/WebSocketContainer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const PaymentRequestModal = NiceModal.create(props => {
    const { t } = useTranslation();
    const [withHeader, withFooter] = [false, false];
    const [width, height] = ["80%", "80%"];
    const withCloseButton = true;
    const modal = useRef();
    const clickOutsideToClose = true;
    const title = t("");
    const buttonText = t("close");
    const { id } = useParams();
    const [data, setData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.data) {
            const newTotal = props.data.products.reduce((acc, item) => acc + item.quantity * item.price, 0);

            let requestData = {
                "returnUrl": props.data.returnUrl,
                "orderProducts": props.data.products,
                "userName": props.data.userName,
                "amount": newTotal,
                "storeCode": props.data.storeCode
            }
            setData(requestData);
        }
    }, [props.data])


    const onPaymentRequestClck = () => {
        
        requester.testPaymentRequest(data, (result) => {
            if (result.code === 0) {
                
                modal.current.close();
                let ready = result.data;
                requester.testPayment(ready, (result) => {
                    if (result.code === 0) {
                        navigate("/test2/payment", { state: { data: result.data } })
                    }
                })


            } else {
                toast.error("문제가 발생했습니다 관리자에게 문의하세요", {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });
            }
        })
    }


    return (
        <ModalBase
            ref={modal}
            width={width}
            height={height}
            withHeader={withHeader}
            withFooter={withFooter}
            withCloseButton={withCloseButton}
            clickOutsideToClose={clickOutsideToClose}
            title={title}
            buttonText={buttonText}
            overflow={"visible"}
            borderRadius={"25px"}
            onClose={props.onClose}
        >
            <VerticalFlex padding={"4vw"}>
                <FlexChild>

                    <CustomButton text={"결제하기"} onClick={onPaymentRequestClck} />
                </FlexChild>
            </VerticalFlex>
        </ModalBase>
    );
});

export default PaymentRequestModal;
