import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import happyPuffuLogo from "resources/img/puffu/happyPuffuIcon.svg";

function VendingComplete() {

    return (
        <Container height={"80vh"} backgroundColor={"var(--bg-color)"}>
            <VerticalFlex>
                <FlexChild />
                <FlexChild>
                    <img src={happyPuffuLogo} width={"20%"} />
                </FlexChild>
                <FlexChild>
                    <P size={"6vw"} weight={900} color={"white"}>
                        인증이 완료되었습니다 !
                    </P>
                    <P size={"6vw"} weight={900} color={"white"}>
                        키오스크 화면을 확인해주세요.
                    </P>

                </FlexChild>
                <FlexChild />
            </VerticalFlex>
        </Container>
    );
}

export default VendingComplete;