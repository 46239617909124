import NiceModal from "@ebay/nice-modal-react";
import { bffRequester, requester } from "App";
import Dummy from "components/Dummy";

import P from "components/P";
import CustomButton from "components/buttons/CustomButton";
import InputText from "components/inputs/signUp/InputText";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import ModalBase from "modals/base/ModalBase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom/dist";
// import puffuIcon from "resources/img/puffu/puffuIcon.png";
// import { HOST } from "routes/front/main/WebSocketContainer";

const ConfirmModal = NiceModal.create(props => {
    const { t } = useTranslation();
    const [withHeader, withFooter] = [false, false];
    const [width, height] = ["100%", "60%"];
    const withCloseButton = true;
    const modal = useRef();
    const clickOutsideToClose = true;
    const title = t("");
    const buttonText = t("close");
    const navigate = useNavigate();
    const authNumberRef = useRef();



    const passOnClick = () => {

        const outerMOKConfirmData = props.data.MOKConfirmData;
        const innerMOKConfirmData = JSON.parse(outerMOKConfirmData);
        if (innerMOKConfirmData.resultCode === "2000") {
            let mobileDuplicateData = { mobileNumber: props.info.userPhoneNum, storeId: "root", role: "MEMBER" }
            requester.membermobileNumDuplicate(mobileDuplicateData, (result) => {
                if (result.code === 0) {
                    if (result.data) {
                        alert("이미 가입된 회원입니다.");
                        modal.current.close();
                    } else {

                        modal.current.close();
                        navigate("/signUp/result", { state: { data: props.data, token: props.token, info: props.info, type: props.type, storeCode: props.storeCode } })
                    }
                }
            })
        } else {
            
            modal.current.close();
        }

    }
    const smsOnClick = () => {

        const outerMOKConfirmData = props.data.MOKConfirmData;
        const innerMOKConfirmData = JSON.parse(outerMOKConfirmData);
        if (innerMOKConfirmData.resultCode === "2000") {
            let confirmdata =
            {
                MOKConfirmData: innerMOKConfirmData,
                session: props.token.session,
                authNumber: authNumberRef.current.getValue()
            };

            bffRequester.apiResult(confirmdata, (result) => {


                if (result.resultData) {
                    let mobileDuplicateData = { mobileNumber: props.info.userPhoneNum, storeId: "root", role: "MEMBER" }
                    requester.membermobileNumDuplicate(mobileDuplicateData, (result) => {
                        if (result.code === 0) {
                            if (result.data) {
                                alert("이미 가입된 회원입니다.");
                                modal.current.close();

                            } else {
                                navigate("/signUp/result", { state: { data: props.data, token: props.token, authNumber: authNumberRef.current.getValue(), info: props.info, type: props.type, storeCode: props.storeCode } })
                                modal.current.close();
                            }
                        }
                    })

                } else {
                    alert("인증번호를 다시 확인해주세요");
                }
            })
        }

    }
    const btnStyle = {
        width: "100%",
        backgroundColor: "var(--main-color)",
        border: "none",
        height: "48px",
        fontWeight: "bold"
    }

    return (
        <ModalBase
            slideUp
            ref={modal}
            width={width}
            height={height}
            withHeader={withHeader}
            withFooter={withFooter}
            withCloseButton={withCloseButton}
            clickOutsideToClose={clickOutsideToClose}
            title={title}
            buttonText={buttonText}
            overflow={"visible"}
            borderRadius={"25px"}
            onClose={props.onClose}
        >
            {
                props.data &&
                    props.type === "SMS"
                    ?
                    <VerticalFlex gap={10} padding={30}>

                        <FlexChild height={"initial"}>
                            <Dummy height={"2vh"} />
                            {/* <img src={puffuIcon} /> */}
                        </FlexChild>
                        <FlexChild height={"initial"}>
                            <Dummy height={"2vh"} />
                            <Center>
                                <P size={35}>SMS 인증</P>
                            </Center>
                        </FlexChild>
                        <FlexChild width={"70%"} height={"initial"}>
                            <InputText ref={authNumberRef} id="authNumber" name="authNumber" placeHolder={"인증번호를 입력하세요."} />
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <CustomButton style={btnStyle} onClick={smsOnClick} text={"인증확인"} />
                            </Center>
                        </FlexChild>
                    </VerticalFlex>
                    :
                    <VerticalFlex gap={10} padding={30}>
                        <FlexChild height={"initial"}>
                            <Dummy height={"2vh"} />
                            {/* <img src={puffuIcon} /> */}
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <P size={"4vw"}>PASS인증이 완료되었다면</P>
                                <P size={"4vw"}>버튼을 클릭해주세요.</P>
                            </Center>
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <CustomButton style={btnStyle} onClick={passOnClick} text={"PASS인증완료"} />
                            </Center>
                        </FlexChild>
                    </VerticalFlex>
            }

        </ModalBase>
    );
});

export default ConfirmModal;
