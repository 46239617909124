import { Container } from "@mui/material";
import { requester } from "App";
import CustomButton from "components/buttons/CustomButton";
import P from "components/P";
import StarRate from "components/star/StarRate";
import style from "./ProductReview.module.css";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatLocalDate } from "shared/utils/Utils";

function ProductReview() {
    const { id } = useParams();
    const location = useLocation();
    const inputs = useRef([]);
    const [orderProduct, setOrderProduct] = useState();
    const [score, setScore] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (location) {
            setOrderProduct(location.state.product)
        }
    }, [location])
    const onClick = () => {
        if (score) {
            if (inputs.current[0].value.length > 3) {
                let data = {
                    comment: inputs.current[0].value,
                    orderProductId: orderProduct.id,
                    rating: score,
                    reviewImg: null
                }

                requester.createReview(data, (result) => {
                    if (result.code === 0) {
                        navigate(`/ws/${id}/mobile/receipt`)
                        toast.success("리뷰등록이 완료되었습니다.", {
                            autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
            }
            else {
                toast.error("리뷰를 정확히 작성해주세요.", {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });
            }
        } else {
            toast.error("별점을 체크해주세요", {
                autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }
    const starRateChange = (score) => {
        setScore(score);
    }
    const btnStyle = {
        width: "90%",
        fontWeight: 700,
        backgroundColor: "black",
        border: "none"
    }
    return (
        <Container>
            <VerticalFlex gap={10}>
                <FlexChild height={"50px"} />
                <FlexChild>
                    <HorizontalFlex>
                        <FlexChild width={"30%"} padding={"10px"}>
                            <img src={orderProduct && orderProduct.productImg} width={"95%"} />
                        </FlexChild>
                        <FlexChild>
                            <VerticalFlex flexStart>
                                <FlexChild>
                                    <P size={20} color={"white"}>{orderProduct && formatLocalDate(orderProduct.createDateTime)}</P>
                                </FlexChild>
                                <FlexChild>
                                    <P size={20} weight={700} color={"white"}>{orderProduct && orderProduct.productName}</P>
                                </FlexChild>
                            </VerticalFlex>
                        </FlexChild>
                    </HorizontalFlex>
                </FlexChild>
                <FlexChild height={"7px"} backgroundColor={"black"} />
                <FlexChild>
                    <VerticalFlex gap={10}>
                        <FlexChild>
                            <P weight={700} color={"white"}>상품은 어떠셨나요 ? </P>
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <StarRate width={"50"} height={"50"} onChange={starRateChange} review score={score} />
                            </Center>
                        </FlexChild>
                    </VerticalFlex>
                </FlexChild>
                <FlexChild>
                    <VerticalFlex>
                        <FlexChild>
                            <P weight={700} color={"white"}>상품 리뷰를 남겨주세요</P>
                        </FlexChild>
                        <FlexChild>
                            <textarea className={style.textarea} placeholder="상품 리뷰를 남겨주세요" ref={el => (inputs.current[0] = el)} style={{ width: "90%", height: "30vh", resize: "none" }} />
                        </FlexChild>
                    </VerticalFlex>

                </FlexChild>
                <FlexChild>
                    <CustomButton text={"리뷰등록"} onClick={onClick} style={btnStyle} />
                </FlexChild>
                <FlexChild height={"10vh"} />
            </VerticalFlex>
        </Container>
    );
}

export default ProductReview;