import { requester } from "App";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTokenPayload } from "shared/utils/Utils";

function VendingSignIn() {
    const { id } = useParams();
    const { token } = useSelector((state) => ({
        token: state.auth.token,
    }));
    const location = useLocation();
    const tokenData = getTokenPayload(token);
    const [isMounted, setMounted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            const queryParams = new URLSearchParams(location.search);
            const deviceCode = queryParams.get('deviceCode');
            const userAgent = navigator.userAgent.toLowerCase();

            // 웹뷰 감지 로직 강화
            const isWebView = (() => {
                const isIOSWebView = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent);
                const isAndroidWebView = /wv/.test(userAgent) || /android.*samsungbrowser\/(?!.*chrome)/i.test(userAgent);

                return isIOSWebView || isAndroidWebView;
            })();

            const isIPhone = /iphone/i.test(userAgent);

            let machineData = {
                userId: tokenData.userId,
                code: "waitingForProducts",
                roomId: id,
                content: [],
                deviceCode: deviceCode
            };

            if (tokenData) {
                if (isWebView) {
                    
                    requester.testOrder(machineData, (result) => {
                        if (result.code === 0) {
                            if (isWebView) {
                                if (window.BRIDGE && typeof window.BRIDGE.closeWebView === "function") {
                                    window.BRIDGE.closeWebView();
                                } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BRIDGE) {
                                    window.webkit.messageHandlers.BRIDGE.postMessage('closeWebView');
                                } else {
                                    
                                }
                            } else {
                                
                            }
                        }
                    });
                } else {
                    navigate("/memberAccess", { state: { type: "puffuOrder", storeCode: id, deviceCode: deviceCode } })
                }
            } else {
                // 토큰이 없는데 웹뷰가 아니다 
                if (isWebView) {
                    alert("잘못된 접근입니다.")
                    if (window.BRIDGE && typeof window.BRIDGE.closeWebView === "function") {
                        window.BRIDGE.closeWebView();
                    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BRIDGE) {
                        window.webkit.messageHandlers.BRIDGE.postMessage('closeWebView');
                    } else {
                        // navigate(`/ws/${id}/landing`, { state: { data: data } });
                    }
                } else {
                    
                    navigate("/memberAccess", { state: { type: "puffuOrder", storeCode: id, deviceCode: deviceCode } })

                }
            }
        }
    }, [isMounted]);

    return (
        <div style={{ width: "100%" }}>
        </div>
    );
}

export default VendingSignIn;
