import { Route, Routes } from "react-router-dom";
import FrontRouter from "./FrontRouter";


function RootRouter(props) {
    return (
        <Routes>
            <Route path={"/*"} element={<FrontRouter />} />
        </Routes>
    );
}

export default RootRouter;