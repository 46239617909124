import { requester } from "App";
import CustomButton from "components/buttons/CustomButton";
import Container from "layouts/container/Container";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthReducer } from "shared/redux/reducers/auth/AuthReducer";
import { getTokenPayload } from "shared/utils/Utils";

function PaymentHistoryGateway() {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useSelector((state) => ({
        token: state.auth.token,
    }));
    const tokenData = getTokenPayload(token);
    const dispatch = useDispatch();

    const [isMounted, setMounted] = useState(false);
    const [data, setData] = useState();
    useEffect(() => {
        setMounted(true);
    }, [])
    useEffect(() => {
        if (isMounted) {
            const queryParams = new URLSearchParams(location.search);
            const idParams = queryParams.get('id');
            setData({
                id: idParams
                
            });
        }
    }, [isMounted])
    useEffect(() => {
        if (data)
            requester.findByPaymentHistory(data, (result) => {
                let res = result.data;
                if(result.code === 0){
                    navigate(`/ws/${res.storeCode}/landingGateWay?data=${res.machineData}&trxId=${res.trxId}&trackId=${res.trackId}&amount=${res.amount}&storeCode=${res.storeCode}&deviceCode=${res.deviceCode}`)
                }
            })
    }, [data]);
    return (
        <Container>

        </Container>
    );
}

export default PaymentHistoryGateway;
