import { CFormInput } from "@coreui/react";
import clsx from "classnames";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./InputPassword.module.css";
import viewImg from "resources/login/image 21.png";
import P from "components/P";
import Center from "layouts/wrapper/Center";
import Dummy from "components/Dummy";


const InputPassword = forwardRef((props, ref) => {
  const { isMobile } = useContext(BrowserDetectContext);
  const { mobileSignUp, mobileSignIn, confirmVisible, regExp, placeHolder, placeHolderComfirm, label, readOnly, signIn, signUp, targetRef } = props;
  const { t } = useTranslation();
  const input = useRef();
  const [name, setName] = useState(props.name || "");
  const [valuePw, setValuePw] = useState("");
  const [valuePwComfirm, setValuePwComfirm] = useState("");
  const [isMatched, setMatched] = useState(confirmVisible ? false : true);
  const [isValid, setValid] = useState(regExp ? false : true);
  const [isEmptyPw, setEmptyPw] = useState(true);
  const [isEmptyPwConfirm, setEmptyPwConfirm] = useState(true);
  const [helperText, setHelperText] = useState("");
  const [confirmHelperText, setConfirmHelperText] = useState("");
  const [isDefault, setDefault] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onChangePw = (e) => {
    let value = e.target.value;
    let noWhiteSpace = value.replace(/ /gi, "");

    if (noWhiteSpace.length > 0) {
      setEmptyPw(false);
    } else {
      setEmptyPw(true);
    }

    setValuePw(noWhiteSpace);
  };

  const onChangePwComfirm = (e) => {
    let value = e.target.value;
    let noWhiteSpace = value.replace(/ /gi, "");

    if (noWhiteSpace.length > 0) {
      setEmptyPwConfirm(false);
    } else {
      setEmptyPwConfirm(true);
    }
    setValuePwComfirm(noWhiteSpace);
  }

  const onPasswordViewClick = () => {
    setShowPassword(!showPassword);

  }
  

  useImperativeHandle(ref, () => ({
    getValue() {
      return valuePw;
    },
    setValue(value) {
      setValuePw(value);
    },
    isValid() {
      return isValid && isMatched;
    },
    empty() {
      setValuePw("");
    },
    focus() {
      input.current.scrollTo(0, input.current.offsetTop);
      if (props.hidden) {
        targetRef.current.focus();
      } else {
        input.current.focus();
      }
    }
  }));

  useEffect(() => {
    if (regExp) {
      let validationResult = true;
      regExp.map((re) => {
        let eachValidationResult = re.exp.test(valuePw);
        if (eachValidationResult === false) {
          validationResult = false;
        }

      });

      setValid(validationResult);
    } else {
      setValid(true);
    }

    if (!isEmptyPw && !isEmptyPwConfirm) {
      if (valuePw === valuePwComfirm) {
        setMatched(true);
      } else {
        setMatched(false);
      }
    }

  }, [valuePw, valuePwComfirm]);

  const inputStyle = {
    border: "none",
    boxShadow: "none",
    borderRadius: "0",
    fontSize: "15px",
    padding: "0 20px",
    height: "48px",

  }
  const defaultInputStyle = {
    '--cui-invalid-feedback': "none",
    boxShadow: "none",
    borderRadius: "0",
    fontSize: "15px",
    border: "1px solid var(--line-color)",
  }
  const mobileDefaultInputStyle = {
    '--cui-invalid-feedback': "none",
    boxShadow: "none",
    borderRadius: "10px",
    fontSize: "15px",
    border: "1px solid var(--line-color)",
    height: "50px"
  }
  const mobileDefaultComfirmInputStyle = {
    '--cui-invalid-feedback': "none",
    boxShadow: "none",
    borderRadius: "10px",
    fontSize: "15px",
    border: "1px solid var(--line-color)",
    height: "50px",
    backgroundColor: "#F0F1F3"
  }

  return (
    <>
      {
        mobileSignIn &&
        <div style={{ position: "relative" }}>
          <CFormInput
            onKeyUp={props.onKeyPress}
            style={mobileDefaultInputStyle}
            type={showPassword ? "text" : "password"}
            // valid={readOnly ? null : isEmptyPw ? null : isValid}
            // invalid={isEmptyPw ? null : !isValid}
            value={valuePw || ""}
            onChange={onChangePw}
            placeholder={placeHolder}
            label={label}
            ref={input}
            size={props.size}

          />
          <img src={viewImg} onClick={onPasswordViewClick} style={{ position: "absolute", top: "25%", right: "5%", width: "25px", height: "25px", cursor: "pointer" }} />
        </div>
      }
      {
        signIn &&

        <CFormInput
          onKeyUp={props.onKeyPress}
          style={defaultInputStyle}
          type="password"
          // valid={readOnly ? null : isEmptyPw ? null : isValid}
          // invalid={isEmptyPw ? null : !isValid}
          value={valuePw || ""}
          onChange={onChangePw}
          placeholder={placeHolder}
          label={label}
          ref={input}
          size={props.size}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && props.onEnterPress) {
              e.preventDefault(); // 폼 제출 방지
              props.onEnterPress();
            }
          }}
        />
      }
      {mobileSignUp
        &&
        <VerticalFlex gap={10}>
          <FlexChild>
            <CFormInput
              onKeyUp={props.onKeyPress}
              style={mobileDefaultComfirmInputStyle}
              type="password"
              valid={readOnly ? null : isEmptyPw ? null : isValid}
              invalid={isEmptyPw ? null : !isValid}
              value={valuePw || ""}
              onChange={onChangePw}
              placeholder={placeHolder}
              label={label}
              ref={input}
              size={props.size}
            />
            {
              props.guide &&
              <Center width={"98%"}>
                <Dummy height={"5px"} />
                <P textAlign={"left"} size={10}>*비밀번호(대문자, 소문자,숫자,특수문자 포함)</P>
              </Center>
            }
          </FlexChild>
          {confirmVisible
            &&
            <FlexChild>
              <CFormInput
                style={mobileDefaultComfirmInputStyle}
                type="password"
                valid={readOnly ? null : isEmptyPwConfirm ? null : isMatched}
                invalid={isEmptyPwConfirm ? null : !isMatched}
                value={valuePwComfirm || ""}
                onChange={onChangePwComfirm}
                placeholder={placeHolderComfirm}

              />
            </FlexChild>
          }
        </VerticalFlex>
      }
      {signUp
        &&
        <VerticalFlex gap={10}>
          <FlexChild>
            <CFormInput
              onKeyUp={props.onKeyPress}
              style={defaultInputStyle}
              type="password"
              valid={readOnly ? null : isEmptyPw ? null : isValid}
              invalid={isEmptyPw ? null : !isValid}
              value={valuePw || ""}
              onChange={onChangePw}
              placeholder={placeHolder}
              label={label}
              ref={input}
              size={props.size}
            />
          </FlexChild>
          {confirmVisible
            &&
            <FlexChild>
              <CFormInput
                style={defaultInputStyle}
                type="password"
                valid={readOnly ? null : isEmptyPwConfirm ? null : isMatched}
                invalid={isEmptyPwConfirm ? null : !isMatched}
                value={valuePwComfirm || ""}
                onChange={onChangePwComfirm}
                placeholder={placeHolderComfirm}

              />
            </FlexChild>
          }
        </VerticalFlex>
      }
      {
        props.line
        &&
        <VerticalFlex>
          <FlexChild height={50}>
            <HorizontalFlex justifyContent={"flex-start"}>
              <FlexChild width={isMobile ? "" : "15%"} >
                <div className={clsx(style.wrap, { [style.active]: isValid })}>
                  <CFormInput
                    style={inputStyle}
                    type="password"
                    value={valuePw || ""}
                    onChange={onChangePw}
                    placeHolder={placeHolder}
                    ref={input}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && props.onEnterPress) {
                        e.preventDefault(); // 폼 제출 방지
                        props.onEnterPress();
                      }
                    }}
                  // valid={readOnly ? null : isEmptyPw ? "" : isValid}
                  // invalid={isEmptyPw ? "" : !isValid}
                  />
                  {/* <p className={clsx(style.placeHolder, { [style.readOnly]: readOnly }, { [style.active]: passwordActive }, { [style.defaultPlaceHolder]: !passwordActive })}>{props.placeHolder}</p> */}
                </div>

              </FlexChild>
            </HorizontalFlex>
            <div className={style.borderBottom}></div>
          </FlexChild>

          {confirmVisible
            &&
            <FlexChild height={50}>
              <HorizontalFlex justifyContent={"flex-start"}>
                <FlexChild width={isMobile ? "" : "15%"}>
                  {/* <div className={clsx(style.wrap, { [style.active]: isValid && passwordComfirmActive ? !passwordComfirmActive : passwordComfirmActive })}>
                    <CFormInput
                      style={inputStyle}
                      type="password"
                      value={valuePwComfirm || ""}
                      onChange={onChangePwComfirm}
                      valid={readOnly ? null : isEmptyPwConfirm ? "" : isValid}
                      invalid={isEmptyPwConfirm ? "" : !isValid}
                    />
                    <p className={clsx(style.placeHolder, { [style.readOnly]: readOnly }, { [style.active]: passwordComfirmActive }, { [style.defaultPlaceHolder]: !passwordComfirmActive })}>{props.placeHolderComfirm}</p>
                  </div> */}
                </FlexChild>
              </HorizontalFlex>
            </FlexChild>

          }
        </VerticalFlex>
      }
    </>
  );
});


export default InputPassword;
