import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Center from "layouts/wrapper/Center";
import style from "./ModalBase.module.css"

function ModalBaseMain(props) {
  return (
    <div className={style.main} style={{ padding: (props.padding ? props.padding + "px" : null), }}>
      {props.withCloseButton &&
        <div className={style.closeButton} style={{ borderRadius: "100%" }} onClick={props.modalClose}>
          <Center>
            <FontAwesomeIcon icon={fas["faTimes"]} color={"white"} />
          </Center>
        </div>
      }
      {props.children}
    </div>
  );
}

export default ModalBaseMain;