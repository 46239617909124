import { useLocation, useNavigate, useParams } from "react-router-dom";
import style from "./Receipt.module.css";
import { useContext, useEffect, useState } from "react";
import { requester } from "App";
import { AuthContext } from "providers/AuthProvider";
import VerticalFlex from "layouts/flex/VerticalFlex";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import Center from "layouts/wrapper/Center";
import P from "components/P";
import { formatLocalDate } from "shared/utils/Utils";
import Dummy from "components/Dummy";
import NiceModal from "@ebay/nice-modal-react";
import BackButton from "components/buttons/BackButton";

function Receipt() {

    const { userId, userName } = useContext(AuthContext);
    const { id } = useParams();
    const [orderList, setOrderList] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (userId) {

            let data = {}
            requester.findAllByOrderProduct(data, (result) => {
                
                setOrderList(result.data);
            })

        }


    }, [userId])



    const PurchaseOrderCard = ({ data }) => {
        const onReviewClick = (e) => {
            e.stopPropagation();
            navigate(`/ws/${id}/mobile/receipt/review/${data.id}`, { state: { product: data } });

        }

        return (

            <VerticalFlex gap={15}>
                <FlexChild width={"100%"} height={"3px"} backgroundColor={"black"} />
                <FlexChild>
                    <div>
                        <HorizontalFlex flexStart gap={20}>
                            <FlexChild width={"30%"}>
                                <VerticalFlex>
                                    <FlexChild>
                                        <Center>
                                            <P weight={700} size={18} color={"white"}>{formatLocalDate(data && data.createDateTime)}</P>
                                        </Center>
                                    </FlexChild>
                                    <FlexChild>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}>
                                            <img src={data && data.productImg} width={"100%"} style={{ borderRadius: "10px" }} />
                                        </div>
                                    </FlexChild>
                                </VerticalFlex>
                            </FlexChild>
                            <FlexChild>
                                <VerticalFlex>
                                    <FlexChild width={"100%"}>
                                        <HorizontalFlex padding={"0 10px 0 0"}>

                                            <FlexChild justifyContent={"flex-end"}>
                                                <P color={"#aaa"}> </P>
                                            </FlexChild>
                                        </HorizontalFlex>
                                    </FlexChild>
                                    <FlexChild height={"50px"} />
                                    <FlexChild>
                                        <Center textAlign={"left"} width={"100%"}>
                                            <P color={"white"}>{data && data.productName}</P>
                                        </Center>
                                    </FlexChild>
                                    <FlexChild>
                                        <Center textAlign={"left"} width={"100%"}>
                                            <P size={24} color={"white"} weight={700} >{data.price ? (data.price * data.quantity).toLocaleString() : "0"}원</P>
                                        </Center>
                                    </FlexChild>
                                    <FlexChild alignItems={"flex-start"}>
                                        <div onClick={data.reviewYn ? null : onReviewClick} style={{ borderRadius: "5px", height: "35px", border: data.reviewYn ? "1.5px solid #eee" : "1.5px solid var(--main-color)", width: "90%", cursor: data.reviewYn ? null : "pointer" }}>
                                            <Center>
                                                <P weight={700} color={data.reviewYn ? "#eee" : "var(--main-color)"}>{data.reviewYn ? "리뷰 작성 완료" : "리뷰 등록"}</P>
                                            </Center>
                                        </div>
                                    </FlexChild>
                                </VerticalFlex>
                            </FlexChild>
                        </HorizontalFlex>
                    </div>
                </FlexChild>
            </VerticalFlex>

        );
    }


    return (
        <div style={{ height: "95vh" }}>
            <div style={{ backgroundColor: "var(--bg-color)" }}>
                <Dummy height={"50px"} />
                <VerticalFlex gap={10} >

                    {
                        orderList &&
                            orderList.length > 0
                            ?
                            orderList.map((data, index) => (
                                <FlexChild height={"200px"}>
                                    <PurchaseOrderCard data={data} />
                                </FlexChild>
                            ))
                            :
                            <FlexChild padding={30}>
                                
                            </FlexChild>

                    }



                </VerticalFlex>
                <Dummy height={"12vh"} />
            </div>

        </div >
    );
}

export default Receipt;