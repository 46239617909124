import NiceModal from "@ebay/nice-modal-react";
import { bffRequester, requester } from "App";
import { mobileNoFormat } from "InitialData/regExp";
import Dummy from "components/Dummy";

import P from "components/P";
import CustomButton from "components/buttons/CustomButton";
import InputText from "components/inputs/signUp/InputText";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import ModalBase from "modals/base/ModalBase";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import puffuIcon from "resources/img/puffu/puffuIcon.svg";
import { AuthReducer } from "shared/redux/reducers/auth/AuthReducer";
import { HistoryReducer } from "shared/redux/reducers/history/HistoryReducer";
import { isAdult } from "shared/utils/Utils";

const NumberVerificationByPaymentModal = NiceModal.create(props => {
    const { t } = useTranslation();
    const [withHeader, withFooter] = [false, false];
    const [width, height] = ["100%", "60%"];
    const withCloseButton = true;
    const modal = useRef();
    const clickOutsideToClose = false;
    const title = t("");
    const buttonText = t("close");
    const navigate = useNavigate();
    const authNumberRef = useRef();
    const { isMobile } = useContext(BrowserDetectContext);
    const dispatch = useDispatch();
    const clickCountRef = useRef(0);




    const passOnClick = () => {
        dispatch(HistoryReducer.actions.setLoading(true));
        validate();
    }
    const smsOnClick = () => {

        const outerMOKConfirmData = props.data.MOKConfirmData;
        const innerMOKConfirmData = JSON.parse(outerMOKConfirmData);
        if (innerMOKConfirmData.resultCode === "2000") {
            let confirmdata =
            {
                MOKConfirmData: innerMOKConfirmData,
                session: props.token.session,
                authNumber: authNumberRef.current.getValue()
            };

            bffRequester.apiResult(confirmdata, (result) => {


                if (result.resultData) {
                    let mobileDuplicateData = { mobileNumber: props.info.userPhoneNum, storeId: "root", role: "MEMBER" }
                    requester.membermobileNumDuplicate(mobileDuplicateData, (result) => {
                        if (result.code === 0) {
                            if (result.data) {
                                alert("이미 가입된 회원입니다.");
                                signIn();
                                modal.current.close();

                            } else {
                                // navigate("/signUp/result", { state: { data: props.data, token: props.token, authNumber: authNumberRef.current.getValue(), info: props.info, type: props.type, storeCode: props.storeCode } })
                                dispatch(HistoryReducer.actions.setLoading(true));
                                
                                validate(props.info.userBirthday);
                                // signUp(confirmdata);
                                modal.current.close();
                            }
                        }
                    })

                } else {
                    alert("인증번호를 다시 확인해주세요");
                }
            })
        }

    }

    const validate = (birthDay) => {
        let adultYn = false;
        const outerMOKConfirmData = props.data.MOKConfirmData;
        const innerMOKConfirmData = JSON.parse(outerMOKConfirmData);
        let validateData = {
            MOKConfirmData: innerMOKConfirmData,
            session: props.token.session,
            authNumber: props.authNumber ? props.authNumber : '',
            type: props.type,
            info: props.info
        }

        if (props.type === "PASS") {

            bffRequester.apiResult(validateData, (result) => {
                
                let adultYn = null;
                if (result.resultData) {
                    adultYn = isAdult(result.resultData.userBirthday)
                } else {

                }
                let mobileDuplicateData = { mobileNumber: props.info.userPhoneNum, storeId: "root", role: "MEMBER" }
                if (adultYn) {
                    requester.membermobileNumDuplicate(mobileDuplicateData, (res) => {
                        if (res.data) {
                            alert("이미 가입된 회원입니다.");
                            signIn();
                            modal.current.close();

                        } else {
                            dispatch(HistoryReducer.actions.setLoading(true));
                            signUp(result.resultData.ci, result.resultData.di, result.resultData.userBirthday);
                            modal.current.close();

                        }
                    });

                } else {
                    if (result.resultData) {
                        alert("성인만 가입할 수 있습니다.");
                    } else {
                        clickCountRef.current += 1;
                        if (clickCountRef.current > 3) {
                            alert("처음부터 다시 시도해주세요");
                            // 카운트를 리셋하여 사용자가 처음부터 다시 시도할 수 있게 합니다.
                            clickCountRef.current = 0;
                            // 추가 실행을 막기 위해 함수에서 반환합니다.
                            modal.current.close();
                            return;
                        } else {
                            alert("인증번호를 다시한번 확인해주세요");
                        }
                    }

                }

            })
        } else if (props.type === "SMS") {
            adultYn = isAdult(props.info.userBirthday);
            if (adultYn) {

                signUp(null, null, birthDay);
                // setMobileNumber(props.info.userPhoneNum);
            } else {
                alert("성인만 가입할 수 있습니다.");
                navigate("/numberSignUp", { replace: true })
            }

        }

    }

    const signIn = () => {
        let data = { storeId: "root", userName: props.info.userPhoneNum, role: "MEMBER" };

        requester.userNumberSignIn(data, (result) => {
            
            if (result.code === -1) {
                if (result.message === "Invalid userName") {
                    //존자하지 않는 아이디
                    toast.error(t("noUserName"), {
                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                    });
                } else if (result.message === "Invalid credentials") {
                    //비번틀림
                    toast.error(t("noPassword"), {
                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error(t("잘못된 연락처"), {
                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            } else if (result.code === 0) {

                let tokenData = result.data.token;
                dispatch(AuthReducer.actions.setToken(tokenData));
                
                navigate(`/ws/${props.paymentData.storeCode}/payment?data=${props.paymentData.machineData}&trxId=${props.paymentData.trxId}&trackId=${props.paymentData.trackId}&amount=${props.paymentData.amount}&storeCode=${props.paymentData.storeCode}&deviceCode=${props.paymentData.deviceCodeData}`);

                // modal.current.close();
                // // navigate("/");

                // 안드로이드 기기인지 확인
                const isAndroid = /Android/i.test(navigator.userAgent);

                if (isAndroid) {
                    // 안드로이드 기기에서 실행할 코드


                    // navigate(`/ws/${props.storeCode}/mobile/android/scan`);
                } else {
                    // 비안드로이드 기기에서 실행할 코드

                    // navigate(`/ws/${props.storeCode}/mobile/scan`);
                }

            }
        })
    }

    const signUp = (ci, di, birthDay) => {
        
        let data = { storeId: "root", name: props.info.userName, mobileNumber: props.info.userPhoneNum, ci: ci, di: di, birthDay: birthDay }

        requester.memberNumberSignUp(data, (result) => {
            if (result.code === 0) {

                // toast.success(t("회원가입이 완료되었습니다."), {
                //     autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                // });
                signIn()
            } else {
                toast.error(t("문제가 발생했습니다 관리자에게 문의하세요"), {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });
            }
            dispatch(HistoryReducer.actions.setLoading(false));
        });
    }

    const btnStyle = {
        width: "100%",
        backgroundColor: "var(--main-color)",
        border: "none",
        height: "48px",
        fontWeight: "bold"
    }

    return (
        <ModalBase
            slideUp
            ref={modal}
            width={width}
            height={height}
            withHeader={withHeader}
            withFooter={withFooter}
            withCloseButton={withCloseButton}
            clickOutsideToClose={clickOutsideToClose}
            title={title}
            buttonText={buttonText}
            overflow={"visible"}
            borderRadius={"25px"}
            onClose={props.onClose}
        >
            {
                props.data &&
                    props.type === "SMS"
                    ?
                    <VerticalFlex gap={10} padding={30} backgroundColor={"var(--bg-color)"}>

                        <FlexChild height={"initial"}>
                            <Dummy height={"2vh"} />
                            <img src={puffuIcon} width={"40%"} />
                        </FlexChild>
                        <FlexChild height={"initial"}>
                            <Dummy height={"2vh"} />
                            <Center>
                                <P color={"white"} weight={700} size={"6.7vw"}>결제 SMS 인증</P>
                            </Center>
                        </FlexChild>
                        <FlexChild width={"70%"} height={"initial"}>
                            <InputText ref={authNumberRef} id="authNumber" name="authNumber" placeHolder={"인증번호를 입력하세요."} />
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <CustomButton style={btnStyle} onClick={smsOnClick} text={"인증확인"} />
                            </Center>
                        </FlexChild>
                    </VerticalFlex>
                    :
                    <VerticalFlex gap={10} padding={30} backgroundColor={"var(--bg-color)"}>
                        <FlexChild height={"initial"}>
                            <Dummy height={"2vh"} />
                            <img src={puffuIcon} width={"40%"} />
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <P color={"white"} weight={700} size={"6.7vw"}>결제 휴대폰 PASS인증</P>
                                <Dummy height={"10px"} />
                                <P color={"white"} weight={700} size={"4vw"}>PASS인증이 완료되었다면</P>
                                <P size={"4vw"} weight={700} color={"white"}>버튼을 클릭해주세요.</P>
                            </Center>
                        </FlexChild>
                        <FlexChild>
                            <Center>
                                <CustomButton style={btnStyle} onClick={passOnClick} text={"PASS인증완료"} />
                            </Center>
                        </FlexChild>
                    </VerticalFlex>
            }

        </ModalBase>
    );
});

export default NumberVerificationByPaymentModal;
