import axios from "axios";

class BffRequester {
  instance = null;
  //운영
  constructor() {
    axios.defaults.withCredentials = true;
    this.instance = axios.create({
      baseURL: "https://puffuandroid.com/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      origin: "https://puffuandroid.com/",
      withCredentials: true,
    });
  }

  setAuthTokenHeader(token) {
    this.instance = axios.create({
      baseURL: "https://puffuandroid.com/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      origin: "https://puffuandroid.com/",
      withCredentials: true,
    });
  }

  async post(url, data) {
    let instance = this.instance;
    let result = "";
    try {
      return new Promise(function (resolve, reject) {
        instance.post(url, JSON.stringify(data))
          .then((res) => {
            result = res.data;
          })
          .catch((error) => {
            if (error.response) {
              result = error.response.data;
              if (error.response.status === 401) {
                // invalid token
                result.code = error.response.status;
              } else {

              }
            }
            else if (error.request) {

            }
            else {

            }

          })
          .finally(() => {
            resolve(result);
          });
      });
    } catch (e) {

    }
  }
  async get(url, data) {
    let instance = this.instance;
    let result = "";
    let params = "?" + new URLSearchParams(data).toString();
    try {
      return new Promise(function (resolve, reject) {
        instance.get(url + params)
          .then((res) => {
            result = res.data;
          })
          .catch((error) => {
            if (error.response) {
              result = error.response.data;
              // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
              if (error.response.status === 401) {
                // invalid token
                result.code = error.response.status;
              } else {

              }
            }
            else if (error.request) {
              // 요청이 이루어 졌으나 응답을 받지 못했습니다.
              // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
              // Node.js의 http.ClientRequest 인스턴스입니다.

            }
            else {
              // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.

            }
          })
          .finally(() => {
            resolve(result);
          });
      });
    } catch (e) {

    }
  }

  async getToken(callback) {
    callback(await this.get("/mok/mok_api_gettoken"));
  }
  async apiRequest(data, callback) {
    callback(await this.post("/mok/mok_api_request", data));
  }
  async apiResult(data, callback) {
    callback(await this.post("/mok/mok_api_result", data));
  }
  async apiConfirmRequest(data, callback) {
    callback(await this.post("/mok/mok_api_confirm_request", data));
  }

}

export default BffRequester;