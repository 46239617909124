import P from 'components/P';
import FlexChild from 'layouts/flex/FlexChild';
import VerticalFlex from 'layouts/flex/VerticalFlex';
import Center from 'layouts/wrapper/Center';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from 'providers/AuthProvider';
import { calculateDistance } from 'shared/utils/Utils';
import StoreBox from './StoreBox';

function FavoritesTab() {
    const favorites = useSelector((state) => state.favorite.favorites);
    const { userName } = useContext(AuthContext);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // iOS 여부 확인
    const isIOS = !!window.webkit?.messageHandlers?.BRIDGE;

    useEffect(() => {
        if (userName === "01088888888") {
            // 특정 위치로 좌표 고정 (예: 서울 시청)
            setLatitude(37.5665); // 원하는 위도로 변경하세요
            setLongitude(126.9780); // 원하는 경도로 변경하세요
            setLoading(false);
        } else if (isIOS) {
            // iOS 브릿지를 통해 위치 정보 요청
            if (window.webkit.messageHandlers.BRIDGE) {
                window.webkit.messageHandlers.BRIDGE.postMessage('requestLocation');
            } else {
                setError('iOS 브릿지 핸들러가 존재하지 않습니다.');
                setLoading(false);
            }

            // iOS로부터 위치 정보를 받는 함수 정의
            window.handleLocationFromIOS = (lat, lon) => {
                setLatitude(parseFloat(lat));
                setLongitude(parseFloat(lon));
                setLoading(false);
            };
        } else {
            // 웹 브라우저에서 위치 정보 가져오기
            if (!navigator.geolocation) {
                setError('Geolocation을 지원하지 않는 브라우저입니다.');
                setLoading(false);
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setLoading(false);
                },
                (error) => {
                    setError('위치 정보를 가져올 수 없습니다.');
                    setLoading(false);
                }
            );
        }
    }, [isIOS, userName]);

    // 즐겨찾기 데이터를 store 객체와 같은 구조로 변환
    const favoritesAsStores = favorites.map((favorite) => ({
        place_name: favorite.placeName,
        address_name: favorite.address,
        road_address_name: favorite.roadAddress,
        x: favorite.x,
        y: favorite.y,
        distance:
            latitude && longitude
                ? calculateDistance(
                      latitude,
                      longitude,
                      parseFloat(favorite.y),
                      parseFloat(favorite.x)
                  )
                : null,
    }));

    if (loading) {
        return (
            <VerticalFlex gap={10} backgroundColor={'var(--bg-color)'}>
                <FlexChild height={'50px'} />
                <Center>
                    <P color={'white'}>로딩 중...</P>
                </Center>
            </VerticalFlex>
        );
    }

    if (error) {
        return (
            <VerticalFlex gap={10} backgroundColor={'var(--bg-color)'}>
                <FlexChild height={'50px'} />
                <Center>
                    <P color={'white'}>{error}</P>
                </Center>
            </VerticalFlex>
        );
    }

    return (
        <VerticalFlex gap={10} backgroundColor={'var(--bg-color)'}>
            <FlexChild width={'100%'}>
                {favoritesAsStores.length > 0 ? (
                    <VerticalFlex gap={10}>
                        {favoritesAsStores.map((store, index) => (
                            <FlexChild key={index}>
                                <StoreBox store={store} />
                            </FlexChild>
                        ))}
                    </VerticalFlex>
                ) : (
                    <Center>
                        <P color={'white'}>즐겨찾기한 매장이 없습니다.</P>
                    </Center>
                )}
            </FlexChild>
            <FlexChild height={'10vh'} />
        </VerticalFlex>
    );
}

export default FavoritesTab;
