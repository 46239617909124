import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from "redux-thunk";
import { AuthReducer } from "shared/redux/reducers/auth/AuthReducer";
import { PaymentReducer } from './redux/reducers/payment/PaymentReducer';
import { SearchConditionReducer } from './redux/reducers/product/SearchConditionReducer';
import { FavoriteReducer } from './redux/reducers/shopping/FavoriteReducer';
import { OrderInfoReducer } from './redux/reducers/shopping/OrderInfoReducer';

const persistConfig = {
    key: 'root',
    storage: storage, // 저장 공간
    whitelist: ['orderInfo', 'cart'], // 유지하고 싶은 값
    blacklist: [] // 유지하지 않을 내용 (새로고침했을 때 날려도 괜찮은 것)
};


const rootReducer = combineReducers({
    auth: AuthReducer.reducer,
    orderInfo: OrderInfoReducer.reducer,
    searchCondition: SearchConditionReducer.reducer,
    payment: PaymentReducer.reducer,
    
    favorite: FavoriteReducer.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [ReduxThunk],
    devTools: true,
})

// export default store;