import P from "components/P";
import Container from "layouts/container/Container";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { decode } from "shared/utils/Utils";

function Payment2(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [trxId, setTrxId] = useState();
    const [trackId, setTrackId] = useState();
    const [amount, setAmount] = useState();
    const [storeCode, setStoreCode] = useState();
    const [nestPayReady, setNestPayReady] = useState(false);
    const [machineData, setMachineData] = useState();
    const [deviceCodeData, setDeviceCodeData] = useState();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const dataParams = queryParams.get('data');
        const trxIdParams = queryParams.get('trxId');
        const trackIdParam = queryParams.get('trackId');
        const amountParam = queryParams.get('amount');
        const storeCodeParam = queryParams.get('storeCode');
        const deviceCodeParam = queryParams.get('deviceCode');
        
        setTrxId(trxIdParams);
        setTrackId(trackIdParam)
        setAmount(amountParam)
        setStoreCode(storeCodeParam);
        setMachineData(dataParams);
        setDeviceCodeData(deviceCodeParam);
        setNestPayReady(true);

    }, [])


    useEffect(() => {
        if (nestPayReady) {
            if (!window._babelPolyfill) { // babel-polyfill이 이미 로드되었는지 확인
                
                
                const jsUrl = "https://tapi.nestpay.co.kr/static/v1/public/nestpay.v1.js";
                
                // const jsUrl = "https://api.nestpay.co.kr/static/v1/public/nestpay.v1.js";
                const jsElement = document.createElement("script");
                jsElement.src = jsUrl + "?ver=" + new Date().getTime();
                
                jsElement.onload = () => {
                    
                    if (window.NESTPAY) {
                        window.NESTPAY.welcome();
                        window.NESTPAY.pay({
                            payMethod: "card",
                            trxId: trxId,
                            openType: 'layer',
                            onApprove: function (response) {
                                
                                
                                if (response.resultCd === "0000") {
                                    
                                    window.location.replace(`/ws/${storeCode}/payment/gateway?type=product&orderId=${trackId}&amount=${amount}&storeCode=${storeCode}&data=${machineData}&deviceCode=${deviceCodeData}&trxId=${trxId}`);
                                } else {
                                    if (window.BRIDGE && typeof window.BRIDGE.closeWebView) {
                                        window.BRIDGE.closeWebView();
                                    }else if (window.webkit.messageHandlers && window.webkit.messageHandlers.BRIDGE) {
                                        window.webkit.messageHandlers.BRIDGE.postMessage('closeWebView');
                                        
                                    }else{
                                        
                                    }
                                }
    
                            }
                        });
                    }
                };
                document.head.appendChild(jsElement);
                return () => {
                    document.head.removeChild(jsElement);
                };
            }
        }
    }, [nestPayReady]);

    return (
        <Container>
            
        </Container>
    );
}

export default Payment2;