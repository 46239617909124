import NiceModal from "@ebay/nice-modal-react";
import { Container } from "@mui/material";
import { requester } from "App";
import { mobileNoFormat, passwordFormat, textFormat, userNameFormat } from "InitialData/regExp";
import P from "components/P";
import CustomButton from "components/buttons/CustomButton";
import EmailDomainSelector from "components/inputs/email/EmailDomainSelector";
import InputPassword from "components/inputs/signUp/InputPassword";
import InputText from "components/inputs/signUp/InputText";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { HistoryReducer } from "shared/redux/reducers/history/HistoryReducer";
import { validateInputs } from "shared/utils/Utils";
import IdentityVerification from "./IdentityVerification";

function MemberSignUp(props) {
    const { id } = useParams();
    const { isMobile } = useContext(BrowserDetectContext);
    const inputs = useRef([]);
    const location = useLocation();
    const [duplicateCheck, setDuplicateCheck] = useState(true);
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    
    const btnStyle = {
        backgroundColor: "var(--main-color)",
        border: "none"
    }
    const onDuplicateClick = () => {
        let data = { userName: "", storeId: "root", role: "MEMBER" }
        data.userName = inputs.current[0].getValue();
        // 먼저 형식 유효성 검사를 수행합니다.
        const isFormatValid = userNameFormat.exp.test(data.userName);
        if (!isFormatValid) {
            toast.error("올바른 아이디를 입력해주세요", {
                autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
            });
            inputs.current[0].focus();
            return;
        }

        requester.userDuplicateCheck(data, (result) => {

            if (result.data) {
                toast.error("이미 사용중인 아이디입니다", {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });

                inputs.current[0].focus();
                setDuplicateCheck(result.data);
            } else {
                toast.success(t("available"), {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });
                setDuplicateCheck(result.data);
            }
        });
    }

    const signUp = () => {

        let data = { storeId: "root", userName: "", password: "", mobileNumber: "", email: "" }
        let addressData = { address: "", addressDetail: "", zipcode: "" }

        if (!duplicateCheck) {
            validateInputs(inputs.current).then((isValid) => {
                if (isValid) {
                    
                    data.userName = inputs.current[0].getValue();
                    data.password = inputs.current[1].getValue();
                    data.mobileNumber = inputs.current[2].getValue();
                    data.email = inputs.current[3].getValue() + '@' + inputs.current[4].getSelectedDomain();
                    

                    dispatch(HistoryReducer.actions.setLoading(true));
                    requester.memberSignUp(data, (result) => {
                        if (result.code === 0) {
                            setUserName(data.userName);
                            setPassword(data.password);


                        } else {
                            toast.error(t("문제가 발생했습니다 관리자에게 문의하세요"), {
                                autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                        dispatch(HistoryReducer.actions.setLoading(false));
                    });

                    // 이후 데이터 처리 로직...
                } else {
                    toast.error(t("pleaseCheck"), {
                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                    });

                }
            })

        } else {
            toast.error(t("duplicateCheck"), {
                autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
            });
            inputs.current[0].focus();
        }

    }

    return (
        <>
            
                    <IdentityVerification storeCode={location.state && location.state.storeCode} />
        </>


    );
}

export default MemberSignUp;