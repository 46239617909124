import { requester } from "App";
import { AuthContext } from "providers/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getTokenPayload } from "shared/utils/Utils";

function PaymentGateway() {
    const location = useLocation();
    const [isMounted, setMounted] = useState(false);
    const [status, setStatus] = useState("");
    
    const navigate = useNavigate();
    const { token } = useSelector((state) => ({
        token: state.auth.token,
    }));


    useEffect(() => {
        setMounted(true);
    }, [])

    useEffect(() => {
        
        const queryParams = new URLSearchParams(location.search);
        const type = queryParams.get('type');
        const trxId = queryParams.get('trxId');
        const orderId = queryParams.get('orderId');
        const paymentKey = queryParams.get('paymentKey');
        const amount = queryParams.get('amount');
        const usedPoints = queryParams.get('usedPoints');
        const storeCode = queryParams.get('storeCode');
        const machineData = queryParams.get('data');
        const deviceCodeData = queryParams.get('deviceCode');
        if (isMounted) {

            
            let tokenPayload = getTokenPayload(token);
            let userId = tokenPayload.userId;
            if (userId) {
                if (type === "point") {
                    
                    let data = {
                        id: orderId,
                        price: amount
                    }
                    requester.pointRecharge(data, (result) => {
                        if (result.code === 0) {
                            // navigate("/ws/root/complete");
                        } else if (result.code === -1) {

                        }
                    })
                } else if (type === "product") {
                    let productData = {
                        id: orderId,
                        totalAmount: amount,
                        usedPoints: usedPoints,
                        userId: userId,
                        trxId: trxId,
                        storeCode: storeCode
                    }
                    requester.testChangeOrderStatus(productData, (result) => {
                        
                        if (result.code === 0) {
                            
                            window.location.replace(`/ws/${storeCode}/payment/complete?data=${machineData}&deviceCode=${deviceCodeData}`);
                        } else if (result.code === -1) {
                            window.location.replace(`/ws/${storeCode}/payment/fail`);
                        }else{
                            window.location.replace(`/ws/${storeCode}/payment/fail`);
                        }
                    })
                } else {
                    // navigate("/ws/root/fail")
                }
            }
        }
    }, [isMounted]);

    return (
        <div>
            결제 처리 중...
        </div>
    );
}

export default PaymentGateway;