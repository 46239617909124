

import CustomButton from "components/buttons/CustomButton";
import Dummy from "components/Dummy";
import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext, useEffect, useState } from "react";
import puffu404Icon from "resources/img/puffu/404/puffu404Icon.png";

function PaymentFail() {
    const { isMobile } = useContext(BrowserDetectContext);
    const [app, setApp] = useState(false);
    useEffect(() => {

    })
    const isOurAppWebView = () => {
        if (window.BRIDGE && window.BRIDGE.isOurAppWebView()) {
            setApp(window.BRIDGE.isOurAppWebView());
            return window.BRIDGE.isOurAppWebView();
        } else {
            setApp(false);
            return false;
        }
    }

    const onClick = () => {
        if (window.BRIDGE && typeof window.BRIDGE.closeWebView) {
            window.BRIDGE.closeWebView();
        } else if (window.webkit.messageHandlers && window.webkit.messageHandlers.BRIDGE) {
            window.webkit.messageHandlers.BRIDGE.postMessage('closeWebView');

        } else {

        }
    }
    const btnStyle = {
        backgroundColor: "black",
        color: "white",
        border: "none",
        fontWeight: "bold"
    }

    return (
        <Container backgroundColor={"white"}>
            {
                isMobile
                    ?
                    <Container maxWidth={1200} height={"100vh"}>
                        <VerticalFlex gap={30}>
                            <FlexChild justifyContent={"flex-end"}>

                            </FlexChild>
                            <FlexChild height={"max-content"}>
                                <P weight={700} size={48} color={"black"}>결제실패</P>
                                <P color={"black"} weight={600} size={"3vw"}>
                                    죄송합니다
                                </P>
                                <P color={"black"} weight={600} size={"3vw"}>
                                    결제에 실패하였습니다
                                </P>
                                <P color={"black"} weight={600} size={"3vw"}>
                                    다시시도해주세요
                                </P>
                            </FlexChild>
                            <FlexChild justifyContent={"flex-start"}>
                                <P color={"#ddd"} weight={600} size={"2.5vw"}>
                                    오류가 계속 발생하는 경우
                                </P>
                                <P color={"#ddd"} weight={600} size={"2.5vw"}>
                                    관리자에게 문의하시기 바랍니다.
                                </P>
                            </FlexChild>
                            <FlexChild>
                                <CustomButton text={"닫기"} onClick={onClick} style={btnStyle} />
                            </FlexChild>

                        </VerticalFlex>
                    </Container>
                    :
                    <Container maxWidth={1200} height={"100vh"}>
                        <VerticalFlex gap={30}>
                            <FlexChild justifyContent={"flex-end"}>

                            </FlexChild>
                            <FlexChild>
                                <P weight={700} size={48} color={"black"}>결제실패</P>
                                <P color={"black"} weight={600} size={"1.7vw"}>
                                    죄송합니다
                                </P>
                                <P color={"black"} weight={600} size={"1.7vw"}>
                                    결제에 실패하였습니다
                                </P>
                                <P color={"black"} weight={600} size={"1.7vw"}>
                                    다시시도해주세요
                                </P>
                            </FlexChild>
                            <FlexChild justifyContent={"flex-start"}>
                                <P color={"#ddd"} weight={600} size={"1.1vw"}>
                                    오류가 계속 발생하는 경우
                                </P>
                                <P color={"#ddd"} weight={600} size={"1.1vw"}>
                                    관리자에게 문의하시기 바랍니다.
                                </P>
                                <Dummy height={"20px"} />
                            </FlexChild>
                            {
                                app &&
                                <FlexChild>
                                    <CustomButton text={"닫기"} onClick={onClick} />
                                </FlexChild>

                            }

                        </VerticalFlex>
                    </Container>
            }
        </Container>
    );
}


export default PaymentFail;