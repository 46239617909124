// StoreListTab.js
import P from 'components/P';
import FlexChild from 'layouts/flex/FlexChild';
import VerticalFlex from 'layouts/flex/VerticalFlex';
import Center from 'layouts/wrapper/Center';
import { AuthContext } from 'providers/AuthProvider';
import { BrowserDetectContext } from 'providers/BrowserEventProvider';
import { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { calculateDistance } from 'shared/utils/Utils';
import StoreBox from './StoreBox';

function StoreListTab() {
    const { userName } = useContext(AuthContext);
    const { isMobile } = useContext(BrowserDetectContext);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nearbyStores, setNearbyStores] = useState([]);
    const [displayStores, setDisplayStores] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const isIOS = !!window.webkit?.messageHandlers?.BRIDGE;

    useEffect(() => {
        if (userName === "01088888888") {
            // 특정 위치로 좌표 고정 (예: 서울 시청)
            setLatitude(37.5665); // 원하는 위도로 변경하세요
            setLongitude(126.9780); // 원하는 경도로 변경하세요
            setLoading(false);
        } else if (isIOS) {
            if (window.webkit.messageHandlers.BRIDGE) {
                window.webkit.messageHandlers.BRIDGE.postMessage('requestLocation');
            } else {
                setError('iOS 브릿지 핸들러가 존재하지 않습니다.');
                setLoading(false);
            }
    
            window.handleLocationFromIOS = (lat, lon) => {
                setLatitude(lat);
                setLongitude(lon);
                setLoading(false);
            };
        } else {
            if (!navigator.geolocation) {
                setError('Geolocation을 지원하지 않는 브라우저입니다.');
                setLoading(false);
                return;
            }
    
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setLoading(false);
                },
                (error) => {
                    setError('위치 정보를 가져올 수 없습니다.');
                    setLoading(false);
                }
            );
        }
    }, [isIOS, userName]);

    useEffect(() => {
        if (!loading && !error && latitude && longitude && window.kakao && window.kakao.maps) {
            const kakao = window.kakao;
    
            // userName에 따른 검색 키워드 설정
            const searchKeyword = userName === "01088888888" ? "하레하레" : "무인매장 푸푸";
    
            // 매장 검색
            const ps = new kakao.maps.services.Places();
            ps.keywordSearch(searchKeyword, function (data, status) {
                if (status === kakao.maps.services.Status.OK) {
                    const filteredStores = data.map((store) => {
                        const storeLat = parseFloat(store.y);
                        const storeLng = parseFloat(store.x);
                        const distance = calculateDistance(latitude, longitude, storeLat, storeLng);
                        return { ...store, distance };
                    });
    
                    // 가까운 순으로 정렬
                    const sortedStores = filteredStores.sort((a, b) => a.distance - b.distance);
                    setNearbyStores(sortedStores);
                    // 초기 데이터 설정 (첫 페이지 데이터 로드)
                    setDisplayStores(sortedStores.slice(0, 10));
                    if (sortedStores.length <= 10) {
                        setHasMore(false);
                    }
                } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
                    setError('주변에 해당 매장이 없습니다.');
                } else {
                    setError('매장 검색 중 오류가 발생했습니다.');
                }
            });
        }
    }, [loading, error, latitude, longitude, userName]);
    



    const fetchMoreData = () => {
        if (displayStores.length >= nearbyStores.length) {
            setHasMore(false);
            return;
        }
        // 다음 데이터 로드 (10개씩)
        setDisplayStores((prevStores) => [
            ...prevStores,
            ...nearbyStores.slice(prevStores.length, prevStores.length + 10),
        ]);
    };

    if (loading) return <Center><P color={"white"}>위치 정보를 가져오는 중...</P></Center>;
    if (error) return <Center><P color={"white"}>{error}</P></Center>;

    return (
        <div>
            {nearbyStores && nearbyStores.length > 0 ? (

                <InfiniteScroll
                    dataLength={displayStores.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<P color={"white"}>더 불러오는 중...</P>}
                    endMessage={<P color={"white"}>더 이상 매장이 없습니다.</P>}
                >
                    <VerticalFlex gap={5}>
                        {displayStores.map((store, index) => (
                            <FlexChild key={index}>
                                <StoreBox store={store} />
                            </FlexChild>
                        ))}
                    </VerticalFlex>
                </InfiniteScroll>
            ) : (
                <Center height={'120px'}>
                    <P color={'white'}>주변에 매장이 없습니다</P>
                </Center>
            )}
        </div>
    );
}

export default StoreListTab;
