import NiceModal from "@ebay/nice-modal-react";

import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import ModalBase from "modals/base/ModalBase";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const StoreListModal = NiceModal.create(props => {
    const { t } = useTranslation();
    const [withHeader, withFooter] = [false, false];
    const [width, height] = ["100%", "80%"];
    const withCloseButton = true;
    const modal = useRef();
    const clickOutsideToClose = false;
    const title = t("");
    const buttonText = t("close");



    return (
        <ModalBase
            slideUp
            ref={modal}
            width={width}
            height={height}
            withHeader={withHeader}
            withFooter={withFooter}
            withCloseButton={withCloseButton}
            clickOutsideToClose={clickOutsideToClose}
            title={title}
            buttonText={buttonText}
            overflow={"visible"}
            borderRadius={"25px"}
            onClose={props.onClose}
            backgroundColor={"var(--bg-color)"}
        >
            <VerticalFlex>
                <FlexChild>
                    1
                </FlexChild>
            </VerticalFlex>
        </ModalBase>
    );
});

export default StoreListModal;
