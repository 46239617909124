import { useEffect, useState } from "react";
import style from "./StarRate.module.css";

function StarRate({ score, onChange, ...props }) {
    const AVR_RATE = score ? score * 14 : 0;
    const STAR_IDX_ARR = ['first', 'second', 'third', 'fourth', 'last'];
    const [ratesResArr, setRatesResArr] = useState([0, 0, 0, 0, 0]);

    const calcStarRates = () => {
        let tempStarRatesArr = [0, 0, 0, 0, 0];
        let starVerScore = AVR_RATE;
        let idx = 0;
        while (starVerScore > 14) {
            tempStarRatesArr[idx] = 14;
            idx += 1;
            starVerScore -= 14;
        }
        tempStarRatesArr[idx] = starVerScore;
        return tempStarRatesArr;
    };

    useEffect(() => {
        setRatesResArr(calcStarRates());
    }, [score]);

    const handleStarClick = (idx) => {
        if (props.review) {
            const newScore = idx + 1; // 클릭한 별의 인덱스를 기준으로 점수 계산
            onChange && onChange(newScore); // 부모 컴포넌트로 변경된 점수를 전달
        }
    };

    return (
        <div className={style.wrap} style={props.style}>
            {STAR_IDX_ARR.map((item, idx) => (
                <span
                    key={`${item}_${idx}`}
                    onClick={() => handleStarClick(idx)} // 클릭 이벤트 추가
                    style={{ cursor: 'pointer' }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "20"} height={props.height || "20"} viewBox="0 0 14 13" fill="#cacaca">
                        <clipPath id={`${item}StarClip`}>
                            <rect width={`${ratesResArr[idx]}`} height="20" />
                        </clipPath>
                        <path
                            id={`${item}Star`}
                            d="M9,2l2.163,4.279L16,6.969,12.5,10.3l.826,4.7L9,12.779,4.674,15,5.5,10.3,2,6.969l4.837-.69Z"
                            transform="translate(-2 -2)"
                        />
                        <use clipPath={`url(#${item}StarClip)`} href={`#${item}Star`} fill="var(--main-color)" />
                    </svg>
                </span>
            ))}
        </div>
    );
}


export default StarRate;
