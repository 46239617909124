import NiceModal from "@ebay/nice-modal-react";
import { bffRequester } from "App";
import { mobileNoFormat } from "InitialData/regExp";
import Dummy from "components/Dummy";
import P from "components/P";
import BackButton from "components/buttons/BackButton";
import CustomButton from "components/buttons/CustomButton";
import InputText from "components/inputs/signUp/InputText";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import puffuLogo from "resources/img/puffu/puffuLogo.svg";

function IdentityVerification(props) {
    const { id } = useParams();
    const inputs = useRef([]);
    const [duplicateCheck, setDuplicateCheck] = useState(true);
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [networkOperator, setNetworkOperator] = useState();
    const [authMethod, setAuthMethod] = useState();
    const [gender, setGender] = useState();
    const [nation, setNation] = useState();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const btnStyle = {
        backgroundColor: "var(--main-color)",
        border: "none",
        height: "50px",
        width: "100px",
        color: "black",
        fontWeight: 900
    }



    const onAuthTypeClick = () => {
        
        if (networkOperator) {
            if (authMethod === "PASS") {
                passOnClick()
            } else if (authMethod === "SMS") {
                smsRequestClick()
            } else {
                toast.error("인증방법을 선택해주세요", {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });
            }
        } else {
            toast.error("통신사를 선택해주세요", {
                autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
            });
        }

    }

    const passOnClick = () => {
        // navigate("/admin/mokRequest");
        bffRequester.getToken((result) => {

            let tokenData = result;
            
            let token = {
                "encryptMOKToken": tokenData.encryptMOKToken,
                "publicKey": tokenData.publicKey
            }
            
            let jsonData = JSON.stringify(token);




            let data = {
                "serviceType": "telcoAuth",
                "providerid": networkOperator,
                "reqAuthType": "PASS",
                "usageCode": "01005",
                "userName": inputs.current[1].getValue(),
                "userPhoneNum": inputs.current[0].getValue(),
                "retTransferType": "MOKResult",
                "normalFlag": "Y",
                "MOKAuthRequestData": jsonData
            }
            
            bffRequester.apiRequest(data, (result) => {
                const outerMOKConfirmData = result.MOKConfirmData;
                const innerMOKConfirmData = JSON.parse(outerMOKConfirmData);
                

                if (innerMOKConfirmData.resultCode === "2000") {
                    if (props.payment) {
                        NiceModal.show("numberVerificationByPayment", { data: result, token: tokenData, type: "PASS", info: data, storeCode: props.storeCode, paymentData: props.paymentData })
                    }
                    else if (props.puffuOrder) {
                        NiceModal.show("numberVerificationByPuffuOrder", { data: result, token: tokenData, type: "PASS", info: data, storeCode: props.storeCode, deviceCode: props.deviceCode })
                    }
                    else {
                        NiceModal.show("numberVerification", { data: result, token: tokenData, type: "PASS", info: data, storeCode: props.storeCode })
                    }
                } else {
                    toast.error(innerMOKConfirmData.resultMsg, {
                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            })
        })

    }

    const smsRequestClick = (e) => {
        bffRequester.getToken((result) => {

            let tokenData = result;
            let token = {
                "encryptMOKToken": tokenData.encryptMOKToken,
                "publicKey": tokenData.publicKey
            }
            let jsonData = JSON.stringify(token);


            let data = {
                "serviceType": "telcoAuth",
                "providerid": networkOperator,
                "reqAuthType": "SMS",
                "usageCode": "01005",
                "userName": inputs.current[1].getValue(),
                "userPhoneNum": inputs.current[0].getValue(),
                "userBirthday": inputs.current[5].getValue(),
                "userGender": gender,
                "sendMsg": "본인확인 인증번호[000000]를 화면에 입력해주세요.",
                "replyNumber": "01048947486",
                "retTransferType": "MOKResult",
                "userNation": nation,
                "normalFlag": "Y",
                "MOKAuthRequestData": jsonData
            }
            
            bffRequester.apiRequest(data, (result) => {
                
                const outerMOKConfirmData = result.MOKConfirmData;
                const innerMOKConfirmData = JSON.parse(outerMOKConfirmData);

                if (innerMOKConfirmData.resultCode === "2000") {
                    if (props.payment) {
                        NiceModal.show("numberVerificationByPayment", { data: result, token: tokenData, type: "SMS", info: data, storeCode: props.storeCode, paymentData: props.paymentData })
                    }
                    else if (props.puffuOrder) {
                        NiceModal.show("numberVerificationByPuffuOrder", { data: result, token: tokenData, type: "SMS", info: data, storeCode: props.storeCode, deviceCode: props.deviceCode })
                    }
                    else {
                        NiceModal.show("numberVerification", { data: result, token: tokenData, type: "SMS", info: data, storeCode: props.storeCode })
                    }

                } else {
                    toast.error(innerMOKConfirmData.resultMsg, {
                        autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            })
        })

    }

    const NetworkOperatorBox = ({ list }) => {
        const onNetworkOperatorClick = (data) => {
            setNetworkOperator(data.value);
        }

        return (
            <div style={{ display: "flex", gap: "10px", overflow: "hidden", flexWrap: "wrap" }} >
                {
                    list && list.map((data, index) =>
                        <div key={index} style={{ borderRadius: "5px", display: "flex", justifyContent: "center", flex: "1 1 30%", width: "initial", border: "1px solid #D0D0D0", padding: "15px", backgroundColor: `${networkOperator === data.value ? "var(--main-color)" : "transparent"}` }} onClick={() => onNetworkOperatorClick(data)}>
                            <P weight={700} color={`${networkOperator === data.value ? "black" : "white"}`}>{data.code}</P>
                        </div>
                    )
                }
            </div>
        );
    }
    const AuthMethodBox = ({ list }) => {
        const onAuthMethodClick = (data) => {
            setAuthMethod(data.value);
        }

        return (
            <div style={{ display: "flex", gap: "10px", overflow: "hidden", flex: 1 }} >
                {
                    list && list.map((data, index) =>
                        <div key={index} style={{ borderRadius: "5px", width: "initial", border: "1px solid #D0D0D0", padding: "10px", backgroundColor: `${authMethod === data.value ? "var(--main-color)" : "transparent"}` }} onClick={() => onAuthMethodClick(data)}>
                            <P weight={700} color={`${authMethod === data.value ? "black" : "white"}`}>{data.code}</P>
                        </div>
                    )
                }
            </div>
        );
    }

    const GenderBox = ({ list }) => {
        const onGenderClick = (data) => {
            setGender(data.value);
        }

        return (
            <div style={{ display: "flex", gap: "10px", overflow: "hidden", flex: 1 }} >
                {
                    list && list.map((data, index) =>
                        <div key={index} style={{ borderRadius: "5px",width: "initial", border: "1px solid #D0D0D0", padding: "10px", backgroundColor: `${gender === data.value ? "var(--main-color)" : "transparent"}` }} onClick={() => onGenderClick(data)}>
                            <P weight={gender === data.value ? 900 :700} color={`${gender === data.value ? "black" : "white"}`}>{data.code}</P>
                        </div>
                    )
                }
            </div>
        );
    }

    const NationBox = ({ list }) => {
        const onNationClick = (data) => {
            setNation(data.value);
        }

        return (
            <div style={{ display: "flex", gap: "10px", overflow: "hidden", flex: 1 }} >
                {
                    list && list.map((data, index) =>
                        <div key={index} style={{ borderRadius: "5px",width: "initial", border: "1px solid #D0D0D0", padding: "10px", backgroundColor: `${nation === data.value ? "var(--main-color)" : "transparent"}` }} onClick={() => onNationClick(data)}>
                            <P weight={nation === data.value ? 900 :700} color={`${nation === data.value ? "black" : "white"}`}>{data.code}</P>
                        </div>
                    )
                }
            </div>
        );
    }

    return (
        <VerticalFlex gap={30} backgroundColor={"var(--bg-color)"}>
            <FlexChild width={"100%"}>
                <VerticalFlex>
                    <FlexChild padding={"10px 0"}>
                        <HorizontalFlex>
                            <FlexChild width={30}>
                                <BackButton color="white" />
                            </FlexChild>
                            <FlexChild>
                                <Center>
                                    <P weight={900} size={20} color={"white"}>본인인증</P>
                                </Center>
                                <Dummy width={"30px"} />
                            </FlexChild>

                        </HorizontalFlex>
                    </FlexChild>
                    <FlexChild width={"100%"} height={"1px"} backgroundColor={"#aaa"} />
                </VerticalFlex>
            </FlexChild>
            <FlexChild width={"90%"}>
                <NetworkOperatorBox list={[
                    { code: "SKT", value: "SKT" },
                    { code: "KT", value: "KT" },
                    { code: "LGU", value: "LGU" },
                    { code: "SKT알뜰", value: "SKTMVNO" },
                    { code: "KT알뜰", value: "KTMVNO" },
                    { code: "LGU알뜰", value: "LGUMVNO" },
                ]} />
            </FlexChild>
            <FlexChild width={"90%"}>
                <VerticalFlex gap={10}>
                    <FlexChild >
                        <Center width={"100%"} textAlign={"left"}>
                            <P color={"white"} weight={700}>인증 방식을 선택하세요.</P>
                        </Center>
                    </FlexChild>
                    <FlexChild>
                        <AuthMethodBox list={[
                            { code: "PASS앱", value: "PASS" },
                            { code: "문자메시지", value: "SMS" }
                        ]} />
                    </FlexChild>
                </VerticalFlex>
            </FlexChild>
            <FlexChild width={"90%"}>
                <VerticalFlex gap={10}>
                    <FlexChild >
                        <Center width={"100%"} textAlign={"left"}>
                            <P color={"white"} weight={700}>전화번호</P>
                        </Center>
                    </FlexChild>
                    <FlexChild>
                        <InputText value={props.mobileNumber ? props.mobileNumber : ""} number gray mobileText ref={el => (inputs.current[0] = el)} placeHolder={"전화번호"} regExp={[mobileNoFormat]} />
                    </FlexChild>
                </VerticalFlex>
            </FlexChild>
            <FlexChild width={"90%"}>
                <VerticalFlex gap={10}>
                    <FlexChild >
                        <Center width={"100%"} textAlign={"left"}>
                            <P color={"white"} weight={700}>이름</P>
                        </Center>
                    </FlexChild>
                    <FlexChild>
                        <InputText gray mobileText ref={el => (inputs.current[1] = el)} id="userName" name="userName" placeHolder={"실명을 입력해주세요"} />
                    </FlexChild>
                </VerticalFlex>
            </FlexChild>


            {
                authMethod === "SMS" &&
                <FlexChild>
                    <VerticalFlex gap={10}>
                        <FlexChild width={"90%"}>
                            <VerticalFlex gap={10}>
                                <FlexChild >
                                    <Center width={"100%"} textAlign={"left"}>
                                        <P color={"white"} weight={700}>생년월일</P>
                                    </Center>
                                </FlexChild>
                                <FlexChild>
                                    <InputText gray number mobileText ref={el => (inputs.current[5] = el)} id="userBirthday" name="userBirthday" placeHolder={"생년월일 예시 : 20001101"} />
                                </FlexChild>
                            </VerticalFlex>
                        </FlexChild>

                        <FlexChild width={"90%"}>
                            <VerticalFlex gap={10}>
                                <FlexChild >
                                    <Center width={"100%"} textAlign={"left"}>
                                        <P color={"white"} weight={700}>성별</P>
                                    </Center>
                                </FlexChild>
                                <FlexChild>
                                    <GenderBox list={[
                                        { code: "남", value: "1" },
                                        { code: "여", value: "2" }
                                    ]} />
                                </FlexChild>
                            </VerticalFlex>
                        </FlexChild>

                        <FlexChild width={"90%"}>
                            <VerticalFlex gap={10}>
                                <FlexChild >
                                    <Center width={"100%"} textAlign={"left"}>
                                        <P>내외국인</P>
                                    </Center>
                                </FlexChild>
                                <FlexChild>
                                    <NationBox list={[
                                        { code: "내국인", value: "0" },
                                        { code: "외국인", value: "1" }
                                    ]} />
                                </FlexChild>
                            </VerticalFlex>
                        </FlexChild>
                    </VerticalFlex>
                </FlexChild>
            }

            <FlexChild width={"90%"}>
                <CustomButton text={"인증요청"} style={btnStyle} onClick={onAuthTypeClick} />
                <Dummy height={"50px"} />
            </FlexChild>
        </VerticalFlex >
    );
}

export default IdentityVerification;