import P from "components/P";


function Footer() {

    return (

        <div style={{ textAlign: 'center', paddingTop: '8%', paddingBottom: '3%'}}>
            <P color={'white'} size={'max(0.6vw,11px)'}>
                Copyright 2024.PUFFU Corp. all rights reserved
            </P>
            <P color={'white'} size={'max(0.6vw,11px)'}>
                사업자등록번호 729-86-02229  | 통신판매업신고 : 2024-대전서구-1740
            </P>
            <P color={'white'} size={'max(0.6vw,11px)'}>
                대표: 원태범 | 이메일 : puffuofficial@gmail.com
            </P>
            <P color={'white'} size={'max(0.6vw,11px)'}>
                대전광역시 서구 관저중로95번길 74, 5층 | 고객센터 070-4943-2597
            </P>
        </div>
    )
}

export default Footer;