import { requester } from "App";
import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { setLocalStorage, getTokenPayload } from "shared/utils/Utils";

export const CartReducer = createSlice({
    name: "Cart",
    initialState: {
        products: []
    },
    reducers: {
        addProductToCart: (state, action) => {
            const index = state.products.findIndex(product => product.id === action.payload.id);
            if (index !== -1) {
                // 상품이 이미 있으면 수량만 증가
                state.products[index].quantity += action.payload.quantity;
            } else {
                // 새 상품이면 배열에 추가
                state.products.push(action.payload);
            }
        },
        updateQuantity: (state, action) => {
            const { id, quantity } = action.payload;
            const index = state.products.findIndex(product => product.id === id);
            if (index !== -1) {
                // 상품이 있으면 수량 업데이트
                state.products[index].quantity = quantity;
            }
        },
        // 상품 제거
        removeProductFromCart: (state, action) => {
            state.products = state.products.filter((product) => product.id !== action.payload.id);
        },
        refreshCart: (state, action) => {
            // 전체 상품 목록을 교체
            state.products = action.payload;
        },
        reset: (state) => {
            // 상품 목록을 빈 배열로 초기화
            state.products = [];
        }
    },
    extraReducers: {

    }
});
