
import NiceModal from "@ebay/nice-modal-react";
import Dummy from "components/Dummy";
import P from "components/P";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { useState } from "react";
import activeStar from "resources/img/other/Star 1.svg";
import passiveStar from "resources/img/other/Star 2.svg";
import style from "./StoreBox.module.css";
import puffuIcon from 'resources/img/puffu/puffuIcon.svg';
import { FavoriteReducer } from "shared/redux/reducers/shopping/FavoriteReducer";
import { requester } from "App";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function StoreBox({ store }) {
    const dispatch = useDispatch();
    const distanceInMeters = store.distance * 1000; // km를 m로 변환
    const favorites = useSelector((state) => state.favorite.favorites);
        
        // 즐겨찾기 여부 확인
        const isFavorited = favorites.some(
            (favorite) => favorite.x === store.x && favorite.y === store.y
        );
    const handleClick = () => {
        // 모달을 열고 store 정보를 전달
        NiceModal.show('storeDetail', { store });
    };
    const toggleFavorite = (e) => {
        e.stopPropagation(); // 부모의 onClick 이벤트 전파 방지
        const data = {
            address: store.address_name,
            roadAddress: store.road_address_name,
            placeName: store.place_name,
            x: store.x,
            y: store.y,
        };
        requester.toggleFavorite(data, (result) => {
            if (result.code === 0) {
                // 서버에서 반환한 업데이트된 즐겨찾기 목록으로 상태 갱신
                dispatch(FavoriteReducer.actions.refreshFavorites(result.data));
            } else {
                // 에러 처리
                console.error('즐겨찾기 토글 실패:', result.message);
            }
        });
    };
    return (
        <div onClick={handleClick} className={style.storeBox}>
            <HorizontalFlex>
                <FlexChild width={'20%'} padding={"15px"}>
                    <img src={puffuIcon} width={'100%'} />
                </FlexChild>
                <FlexChild>
                    <VerticalFlex>
                        <FlexChild height={'50%'}>
                            <Center>
                                <P weight={700} width={'23vw'} ellipsis color={'white'}>
                                    {store.place_name}
                                </P>
                            </Center>
                        </FlexChild>
                        <FlexChild>
                            <P color={'white'}>
                                {distanceInMeters < 1000
                                    ? `${distanceInMeters.toFixed(0)} m`
                                    : `${store.distance.toFixed(2)} km`}
                            </P>
                        </FlexChild>
                    </VerticalFlex>
                </FlexChild>
            </HorizontalFlex>
            <div
                onClick={toggleFavorite}
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    cursor: 'pointer',
                    color: 'white',
                    width: "max-content"
                }}
            >
                {isFavorited ? <img src={activeStar} /> : <img src={passiveStar} />}
            </div>
            <Dummy width={"100%"} backgroundColor={"black"} height={"2px"} />
        </div>
    );
};

export default StoreBox;