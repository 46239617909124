import { requester } from "App";
import P from "components/P";
import CustomButton from "components/buttons/CustomButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { decode } from "shared/utils/Utils";
import puffuIcon from "resources/img/puffu/puffuIcon.png";
import VerticalFlex from "layouts/flex/VerticalFlex";
import FlexChild from "layouts/flex/FlexChild";
import Container from "layouts/container/Container";
import Dummy from "components/Dummy";


function PaymentComplete() {
    const { id } = useParams();
    const [data, setData] = useState();
    const [deviceCode, setDeviceCode] = useState();
    const [testState, setTestState] = useState("");
    const [visible, setVisible] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const dataParams = queryParams.get('data');
        const deviceCodeParams = queryParams.get('deviceCode');

        // 결과 출력


        const userAgent = navigator.userAgent.toLowerCase();

        // 웹뷰 감지 로직 강화
        const isWebView = (() => {
            const isIOSWebView = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent);
            const isAndroidWebView = /wv/.test(userAgent) || /android.*samsungbrowser\/(?!.*chrome)/i.test(userAgent);

            return isIOSWebView || isAndroidWebView;
        })();

        const isIPhone = /iphone/i.test(userAgent);

        // 디코딩된 문자열 확인
        if (dataParams) {
            const decodedString = decode(dataParams);
            setVisible(isWebView);
            setData(decodedString);
            setDeviceCode(deviceCodeParams);
        } else {
            navigate("/error");
        }



    }, []);
    useEffect(() => {
        if (data) {

            const jsonArray = JSON.parse(data);

            // 변환된 결과를 담을 JSON 객체 생성
            const resultObject = {};

            // JSON 배열 순회
            for (let i = 0; i < jsonArray.length; i++) {
                // 현재 요소 가져오기
                const itemArray = jsonArray[i];

                // productCode, quantity, deviceId 추출
                const productCode = itemArray[0];
                const quantity = itemArray[1];
                const deviceId = itemArray[2];

                // deviceId를 기준으로 객체에 추가
                if (!resultObject[deviceId]) {
                    resultObject[deviceId] = [];
                }

                resultObject[deviceId].push({
                    productCode: productCode,
                    quantity: quantity
                });
            }

            // 객체를 배열로 변환
            const resultArray = Object.keys(resultObject).map(deviceId => ({
                deviceId: deviceId,
                items: resultObject[deviceId]
            }));

            

            // 결과 출력


            let machineData = {
                userId: "",
                code: "order",
                roomId: id,
                content: resultArray,
                deviceCode: deviceCode

            }

            requester.testOrder(machineData, (result) => {
                navigate(`/ws/${id}/payment/complete`, {replace: true})
            })
        }
    }, [data])
    const onClick = () => {


        const userAgent = navigator.userAgent.toLowerCase();

        // 웹뷰 감지 로직 강화
        const isWebView = (() => {
            const isIOSWebView = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent);
            const isAndroidWebView = /wv/.test(userAgent) || /android.*samsungbrowser\/(?!.*chrome)/i.test(userAgent);

            return isIOSWebView || isAndroidWebView;
        })();

        const isIPhone = /iphone/i.test(userAgent);

        if (isWebView) {

            if (window.BRIDGE && typeof window.BRIDGE.closeWebView === "function") {
                window.BRIDGE.closeWebView();
            } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BRIDGE) {
                window.webkit.messageHandlers.BRIDGE.postMessage('closeWebView');
            } else {

            }
        } else {
            
        }


    }

    const btnStyle = {
        width: "90%",
        backgroundColor: "#40A9FF",
        color: "white",
        border: "none",
        height: "48px"
    }
    return (
        <Container height={"100vh"} backgroundColor={"white"}>
            <VerticalFlex>
                <FlexChild justifyContent={"flex-end"}>
                    
                    <Dummy height={"55px"} />
                    <P size={"7vw"} font={"GmarketSansBold"} color={"black"}>결제완료!</P>
                    <Dummy height={"15px"} />
                    <P color={"black"} size={18} weight={"bold"}>결제가 정상적으로 완료되었습니다.</P>
                </FlexChild>
                <FlexChild>
                    <P>{testState}</P>
                </FlexChild>
                <FlexChild justifyContent={"flex-end"}>
                    {
                        visible &&
                        <CustomButton style={btnStyle} onClick={onClick} text={"돌아가기"} />
                    }

                </FlexChild>
                <FlexChild></FlexChild>

            </VerticalFlex>
        </Container>
    );
}

export default PaymentComplete;