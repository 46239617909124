
import NiceModal from "@ebay/nice-modal-react";
import AuthProvider from "providers/AuthProvider";
import BrowserEventProvider from "providers/BrowserEventProvider";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import RootRouter from "router/RootRouter";
import BffRequester from "shared/BffRequester";

import Requester from "shared/Requester";

import style from "./App.module.css";

export const requester = new Requester();

export const bffRequester = new BffRequester();


function App() {

  useEffect(() => {
    const disableScrollRestoration = () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
      }
    };

    disableScrollRestoration();

    return () => {
      window.removeEventListener('beforeinstallprompt', () => { });
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto';
      }
    };
  }, []);

  return (
    <BrowserEventProvider>
      <div className={style.toast}>
        <ToastContainer />
      </div>
      {/* <Loading isLoading={isLoading} /> */}
      <NiceModal.Provider>
        <AuthProvider>
          <RootRouter />
        </AuthProvider>
      </NiceModal.Provider>
    </BrowserEventProvider>
  );
}

export default App;