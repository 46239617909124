
import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useState, useContext } from "react";
import logo from "resources/img/puffu/puffu.png"
import Footer from "../footer/Footer";
import Dummy from "components/Dummy";
function PrivacyPolicy(props) {
    const { isMobile } = useContext(BrowserDetectContext);
    return (
        <Container backgroundColor={'#282828'}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <VerticalFlex maxWidth={1200}>
                    {
                        props.signIn
                            ?
                            null
                            :
                            <FlexChild maxWidth={1200} padding={'1% 0.75% 0 0.75%'} backgroundColor={'#282828'} position={'fixed'} top={'0%'}>
                                <HorizontalFlex justifyContent={'flex-start'} alignItems={'flex-end'}>
                                    <FlexChild width={'auto'} marginRight={'1.5%'}>
                                        <img src={logo} style={{ width: 'max(90px,8vw)' }} />
                                    </FlexChild>
                                    <FlexChild width={'auto'}>
                                        <P size={'max(22px,1.6vw)'} color={'white'} weight={'700'} lineHeight={'1.5vh'}>
                                            개인정보보호정책
                                            {/* policy 가 들어갈부분  */}
                                        </P>
                                    </FlexChild>
                                </HorizontalFlex>
                                <div style={{ height: '2vh', borderBottom: '0.15vw solid white' }}></div>
                            </FlexChild>

                    }
                    <FlexChild width={'97%'} padding={'45 0 5% 0'} alignItems={'flex-start'}>
                        <FlexChild width={'97%'} padding={'15% 0 5.5% 0'} alignItems={'flex-start'}>
                            <P size={28} color={'white'} weight={'bold'}>
                                개인정보보호정책
                            </P>
                        </FlexChild>
                        <FlexChild >
                            <P size={22} color={'white'} weight={'bold'}>
                                제 1 조 (개인정보 수집 및 처리 목적)
                            </P>
                        </FlexChild>
                        <FlexChild padding={'0% 0 5% 0'} >
                            <P size={18} color={'white'}>1. 푸푸는 서비스 제공에 필요한 기본적인 개인정보만 수집하며, 본인의 취향과 관심사에 맞는 서비스를 위한 추가 정보 수집 시 동의를 구합니다.</P>
                            <P size={18} color={'white'}>2. 푸푸는 자신의 인종이나 민족, 종교와 철학적 신념, 출신 국가, 정치적 의견, 전과, 의료 정보, 성적 지향 등 민감한 개인 정보를 수집하지 않습니다.</P>
                            <FlexChild padding={'3% 0 5% 0'}>
                                <P size={18} color={'white'}>- 회원가입시 동의여부를 확인하기 위해</P>
                                <P size={18} color={'white'}>- 법적 의무를 다하기 위해 약관 및 해당 법률을 위반한 자에 대한 서비스를 중지하고 악의적인 사용을 제한하기 위해</P>
                                <P size={18} color={'white'}>- 고객 서비스를 처리하기 위한 통보 및 통신 채널을 확인하기 위해</P>
                                <P size={18} color={'white'}>- 계정 등록 및 탈퇴 요청을 확인하고, 계약을 관리, 유지, 이행하기 위해</P>
                                <P size={18} color={'white'}>- 등록된 고객에게 서비스를 제공하기 위해</P>
                                <P size={18} color={'white'}>- 청구 및 배송을 위한 정확한 거래 정보를 얻기 위해</P>
                                <P size={18} color={'white'}>- 서비스에 대한 불만을 처리하기 위해</P>
                                <P size={18} color={'white'}>- 고객 서비스 센터를 운영하여 보상 포인트를 상환하고 적립하기 위해</P>
                                <P size={18} color={'white'}>- 상품 또는 서비스 구매에 대한 환불 및 결제 정보를 확인하기 위해</P>
                                <P size={18} color={'white'}>- 개인화된 서비스, 편의성 및 혜택을 제공하기 위해</P>
                                <P size={18} color={'white'}>- 예정된 이벤트, 특별 행사 등을 알려드리기 위해</P>
                                <P size={18} color={'white'}>- 서비스 사용 및 마케팅 동향(개인 및 그룹 통계)을 분석하고 새로운 서비스를 개발하기 위해</P>
                                <P size={18} color={'white'}>- 푸푸와 그 계열사들을 위한 마케팅 활동을 하기 위해</P>
                                <P size={18} color={'white'}>- 정기 뉴스레터를 보내기 위해</P>
                                <P size={18} color={'white'}>- 푸푸 또는 그 계열사가 제공하는 서비스 및 제품을 홍보하기 위해</P>
                                <P size={18} color={'white'}>- 프로모션 및 이벤트 참여 기회 제공(이메일, 소셜미디어 계정 등), 앱 설치 URL 전송 등을 위해</P>
                                <P size={18} color={'white'}>- 푸푸 홈페이지 및 SNS 이벤트 참여하기 위해</P>
                                <P size={18} color={'white'}>- 마케팅 캠페인에 대한 관심과 사용 패턴을 분석하고, 서비스 개발을 위한 통계 분석 및 연구를 수행하기 위해</P>
                                <P size={18} color={'white'}>- 고객분쟁해결을 위해 개인정보 침해에 대비하고 기록을 보존하기 위해</P>
                            </FlexChild>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 2 조 (푸푸가 수집하는 개인정보와 수집방법)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>푸푸는 다음과 같은 개인정보를 수집합니다.</P>
                        </FlexChild>
                        <FlexChild>
                            <P size={18} color={'white'}>① 필수정보</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>회원가입시 (푸푸 온라인에서)</P>
                            <P size={18} color={'white'}>-  생년월일 , 이름</P>
                        </FlexChild>
                        <FlexChild>
                            <P size={18} color={'white'}>② 선택적 정보</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>마케팅 활동을 위해 정보를 사용하는 것에 동의하는 경우</P>
                            <P size={18} color={'white'}>- 생년월일, 성별</P>
                            <P size={18} color={'white'}>이벤트 참여</P>
                            <P size={18} color={'white'}>- 이름, 연락처 번호, 주소, 이메일 주소</P>
                            <P size={18} color={'white'}>푸푸의 SNS에서 이벤트에</P>
                            <P size={18} color={'white'}>쇼핑 또는 이벤트 정보 수신에 동의할 때</P>
                            <P size={18} color={'white'}>- 이메일 주소, APP 푸시 토큰</P>
                            <P size={18} color={'white'}>뉴스레터 수신에 동의할 때</P>
                            <P size={18} color={'white'}>- 이메일 주소, 뉴스레터 수신/개봉 기록</P>
                        </FlexChild>
                        <FlexChild>
                            <P size={18} color={'white'}>③ 서비스 사용 또는 비즈니스 처리 중에 수집/생성된 정보</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>- 서비스 사용 내역(품목 및 서비스의 사용 시간 및 구매 내역)</P>
                            <P size={18} color={'white'}>- 결제/계좌 정지/방문 기록, IP 주소, 쿠키</P>
                            <P size={18} color={'white'}>- 장치 정보(유형, OS 버전 번호, 모델 번호)(*장치 식별 정보는 수집되지 않습니다.)</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 3 조 (개인정보 취급업무의 아웃소싱)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>푸푸는 특정 서비스를 다음 회사에 아웃소싱하고 있습니다. 고객님의 개인정보를 보호하기 위해 관련법에 따라 위탁업체와 계약을 체결하여 필수 약관을 규정하고 있습니다.</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>① 다페이 : 결제 처리</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 4 조 (타사와 개인 정보 공유)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>① 푸푸는 '개인정보의 수집 및 처리 목적'에 명시된 사유만으로 회원님의 개인정보를 처리하며, 그 정보를 다른 목적으로 이용하거나 제3자와 공유하지 않습니다.</P>
                        </FlexChild>
                        <FlexChild>
                            <P size={18} color={'white'}>② 단, 다음과 같은 사유로 개인정보를 공유할 수 있습니다.</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>1. 당신이 동의할 때.</P>
                            <P size={18} color={'white'}>2. 서비스 제공을 위해 지급을 진행할 필요가 있을 때.</P>
                            <P size={18} color={'white'}>3. 통계, 학술 및 시장조사를 위하여 개인정보를 식별할 수 없는 형태로 공유하는 경우</P>
                            <P size={18} color={'white'}>4. 적용 가능한 법률에 의해 요구되는 경우.</P>
                            <P size={18} color={'white'}>5. 해당 법률을 준수하여 조사 기관에서 조사하도록 요구하는 경우.</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 5 조 (개인정보 보유, 처리기간 및 폐기)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>① 수집 목적이 달성되면 푸푸는 귀하의 개인정보를 즉시 폐기합니다. 그러나 해당 법률에 따라 더 긴 보존 기간이 필요하거나 정보의 보존 및 처리 기간 연장에 동의하는 경우 데이터는 의도된 목적으로만 안전하게 저장 및 사용됩니다.</P>
                        </FlexChild>
                        <P size={18} color={'white'}>{'1) 해당 법률에 따라 더 긴 보존 및 처리 기간이 필요한 경우(다음 사항에 한정되지 않음).'}</P>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>- 계약 및 청약철회 기록: 5년</P>
                            <P size={18} color={'white'}>- 물품 지급 및 제공 기록: 5년</P>
                            <P size={18} color={'white'}>- 고객 불만 및 분쟁 해결 기록: 3년</P>
                            <P size={18} color={'white'}>- 방문일시 : 3개월(서비스기간 확인을 위한 마지막 방문일시 제외)</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0'}>
                            <P size={18} color={'white'}>{'2) 반복적인 계정 인출 및 재등록으로 인한 손실 또는 손상을 최소화하고 필요에 따라 계정을 다시 활성화할 수 있도록 계정이 인출된 후 즉시 데이터가 삭제됩니다.'}</P>
                        </FlexChild>
                        <P size={18} color={'white'}>{'3) 「개인정보보호법」 제39조의6에 따라 1년 이상 로그인 활동이 없는 계정의 개인정보는 별도의 데이터베이스 저장소로 이관됩니다.'}</P>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>- 서비스 사용 기록: 적립 및 상환 보상 포인트, 로그인</P>
                            <P size={18} color={'white'}>- 물품 지급 및 제공 기록: 5년</P>
                            <P size={18} color={'white'}>- 서비스 이용 재개, 보상 포인트 적립, 푸푸 온라인 로그인 시 비활성화 계정이 재활성화 됩니다.</P>
                            <P size={18} color={'white'}>- 방문일시 : 3개월(서비스기간 확인을 위한 마지막 방문일시 제외)</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0'}>
                            <P size={18} color={'white'}>{'4) 유효한 상업 신용(예: 보상 포인트)은 만료될 때까지 유지됩니다.'}</P>
                        </FlexChild>
                        <FlexChild>
                            <P size={18} color={'white'}>② 귀하의 정보는 양식에 따라 다음과 같은 방법으로 폐기 또는 삭제됩니다.</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>{'1) 종이에 저장된 개인정보는 분쇄되거나 소각된다.'}</P>
                            <P size={18} color={'white'}>{'2) 전자적으로 저장된 개인 정보는 안전하게 폐기되며 검색할 수 없습니다.'}</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 6 조 (등록된 사용자로서의 권한)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>① 등록된 고객은 언제든지 개인정보를 열람, 수정, 요청할 수 있습니다.</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>{'1) 개인정보 조회 및 개정'}</P>
                        </FlexChild>
                        <P size={18} color={'white'}>{'2) 당신이 푸푸에게 제공을 요청할 수 있는 정보.'}</P>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>- 푸푸가 보유하고 있는 당신의 개인 정보.</P>
                            <P size={18} color={'white'}>- 사용자의 개인 정보 수집, 처리 및 공유에 대한 사용자의 동의 상태.</P>
                            <P size={18} color={'white'}>- 서비스 공급자 및 제3자와 개인 정보 공유 상태</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0'}>
                            <P size={18} color={'white'}>{'3) 프로필에 잘못된 정보를 수정해달라고 요청하면 수정이 적용될 때까지 관련 개인정보가 사용되거나 제공되지 않습니다. 푸푸는 제3자에게 잘못된 정보가 제공될 경우 즉시 정확한 정보를 통보하고 서비스에 신청하도록 안내할 예정이다.'}</P>
                        </FlexChild>
                        <FlexChild padding={'3% 0 5% 0'}>
                            <P size={18} color={'white'}>{'4) 등록 사용자인 경우 푸푸에 개인정보 제공 또는 접근을 요청할 수 있으며, 이 경우 푸푸는 10일 이내에 귀하의 요청에 응답합니다.'}</P>
                        </FlexChild>
                        <FlexChild>
                            <P size={18} color={'white'}>② 개인정보 수집, 처리 및 공유에 대한 개인정보 보호정책에 대한 동의는 언제든지 철회할 수 있습니다.</P>
                            <P padding={'3% 0 5% 0'} size={18} color={'white'}>계좌를 해지하면 푸푸는 즉시 개인정보의 신속한 폐기 등 필요한 조치를 취합니다.</P>
                        </FlexChild>
                        <P size={18} color={'white'}>③ 위 제1조 및 제2조에도 불구하고 다음과 같은 사유로 귀하의 개인정보 열람 또는 정정 요청이 제한될 수 있습니다.</P>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>{'1) 귀하 또는 제3자의 생명, 권리, 이익 또는 자산에 위협이 되는 경우'}</P>
                            <P size={18} color={'white'}>{'2) 서비스 제공업체에 중대한 장애가 발생한 경우'}</P>
                            <P size={18} color={'white'}>{'3) 해당 법률을 위반한 경우'}</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 7 조 (개인 정보 관리자 및 팀)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>푸푸는 귀하의 개인정보를 보호하고 관련 문제를 처리하기 위해 담당자를 지정하여 귀하의 개인정보를 보호하고 있습니다. 개인정보에 대한 문의사항이 있으시면 언제든지 연락주세요. 담당자는 귀하의 문의에 신속하고 성실하게 답변할 것입니다. 개인정보 이외의 문의는 04minomino@gmail.com으로 문의하시기 바랍니다.</P>
                            <P size={18} color={'white'}>- 개인정보담당자 허준 puffuofficial@gmail.com</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 8 조 (자동회수집장치의 운영)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>푸푸는 웹사이트에서 보다 개인화된 경험을 제공하기 위해 수집된 '쿠키'를 사용합니다.
                                쿠키는 푸푸의 웹사이트나 모바일 앱에서 서비스를 이용할 때 방문하는 웹서버가 브라우저로 전송하고 기기의 하드디스크(데스크톱, 스마트폰, 태블릿PC 등)에 저장하는 작은 텍스트 파일입니다.
                            </P>
                            <P size={18} color={'white'}>쿠키는 사용 중인 장치를 식별하지만 사용자는 개인이 아닙니다. 브라우저를 닫거나 로그아웃하면 쿠키가 만료됩니다.</P>
                            <P size={18} color={'white'}>쿠키에서 수집한 데이터는 다음과 같은 용도로 사용할 수 있습니다.</P>
                            <P size={18} color={'white'}>① 방문 빈도와 시간을 분석하고, 당신의 관심사와 사용 패턴을 파악하며, 당신이 참여하는 방문과 이벤트를 대상으로 합니다.</P>
                            <P size={18} color={'white'}>② 웹 사이트에서 보다 개인화된 경험을 위해 가장 많이 본 항목을 추적하고 관심사에 맞는 서비스를 제공합니다.</P>
                            <P size={18} color={'white'}>③ 서비스 개선 이니셔티브를 위한 고객 행동 분석
                                쿠키를 승인하거나 비활성화할 수 있는 권한이 있습니다. 모든 쿠키를 승인하거나, 쿠키가 발행될 때마다 알림을 받도록 선택하거나, 모든 쿠키를 비활성화할 수 있습니다.
                                그러나 쿠키를 비활성화할 때 특정 서비스를 사용하거나 받지 못할 수 있습니다.
                            </P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 9 조 (개인정보 보호대책)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>푸푸는 웹사이트에서 보다 개인화된 경험을 제공하기 위해 수집된 '쿠키'를 사용합니다.
                                푸푸는 고객님의 개인정보 보안을 보장하고 사이버 공격으로 인한 개인정보의 분실, 유출, 도난 및 훼손을 방지하기 위해 다음과 같은 기술/조직적 조치를 수립합니다.
                            </P>
                        </FlexChild>
                        <P size={18} color={'white'}>① 기술적 조치</P>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>1. 개인 정보는 암호로 보호됩니다. 중요한 개인 데이터와 암호는 저장하기 전에 암호화됩니다.</P>
                            <P size={18} color={'white'}>2. 컴퓨터 바이러스의 피해와 개인정보의 침입을 방지하기 위해 백신 프로그램을 정기적으로 사용하고 업데이트합니다.</P>
                            <P size={18} color={'white'}>3. 암호화 알고리즘은 네트워크를 통해 개인 데이터를 안전하게 전송하는 데 사용됩니다.</P>
                            <P size={18} color={'white'}>4. 사이버 공격으로 인한 개인정보 유출 방지 및 불법 침입 탐지를 위해 주기적으로 취약점 평가를 실시하고 침입 탐지 시스템을 설치합니다.</P>
                        </FlexChild>
                        <P size={18} color={'white'}>② 조직적 조치</P>
                        <FlexChild padding={'3% 0 5% 0%'}>
                            <P size={18} color={'white'}>  1. 개인정보를 취급하는 개인의 수는 최소한으로 제한하고 관련 직원들에게 주기적인 교육을 제공합니다.</P>
                            <P size={18} color={'white'}>  2. 개인정보 접근권한은 개인정보처리시스템의 절차에 따라 제공되며 필요시 주기적으로 심사 및 철회됩니다.</P>
                            <P size={18} color={'white'}>  3. 개인 정보 보호 정책 준수에 대한 검사를 수행하기 위한 내부 절차가 수립됩니다.</P>
                            <P size={18} color={'white'}>  4. 푸푸는 개인정보를 처리하는 위탁업체를 관리·감독합니다.</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 10 조 (개인정보 침해행위 신고 및 상담)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>개인정보 이용에 대한 상담 또는 신고가 필요한 경우 다음 기관의 도움을 받을 수 있습니다.</P>
                            <P size={18} color={'white'}>① KISA (+82) 118 privacy.kisa.or.kr	</P>
                            <P size={18} color={'white'}>② Personal Information Dispute Mediation Committee 1833-6972 www.kopico.go.kr</P>
                            <P size={18} color={'white'}>③ Cyber Crime Investigation, Supreme Prosecutors’ Office (+82) 1301 www.spo.go.kr</P>
                            <P size={18} color={'white'}>④ National Police Agency Cyber Bureau (+82) 182 ecrm.cyber.go.kr</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 11 조 (개인정보 보호정책의 고시 및 변경)</P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>② 위에 언급된 개인정보 보호정책은 정부 정책, 해당 법률 및 보안시스템 업데이트에 따라 개정될 수 있으며, 개인정보 보호정책의 추가, 삭제, 수정 등 모든 변경사항에 대해 온라인으로 공지할 예정입니다.</P>
                        </FlexChild>
                        <P size={22} color={'white'} weight={'bold'}>제 12 조 (고유식별정보처리동의) </P>
                        <FlexChild padding={'3% 0 5% 0'} >
                            <P size={18} color={'white'}>“푸푸“(이하 ‘회사’이라 함)이 푸푸 서비스(이하 ‘서비스’라 함)를 제공하기 위해 고유식별정보를 다음과 같이 제공 및 처리하는 것에 동의합니다.</P>
                            <P size={18} color={'white'}>가. 고유식별정보를 제공받는 자 : 회사, 인증사업자, 본인확인기관</P>
                            <P size={18} color={'white'}>나. 고유식별정보를 제공받는 자의 목적 : 연계정보(CI) 생성 및‘기관’ 제공</P>
                            <P size={18} color={'white'}>다. 고유식별정보 : 간편인증시 수집된 이용자의 주민등록번호 또는 외국인등록번호</P>
                            <P size={18} color={'white'}>라. 고유식별정보를 제공받는 자의 보유 및 이용기간 : 연계정보(CI) 생성 후 즉시 폐기</P>
                            <P size={18} color={'white'}>상기 고유식별정보 처리에 대한 내용에 동의하지 않으실 경우 ‘푸푸 서비스’를 이용할 수 없습니다.</P>
                            <Dummy height={"15px"} />
                            <P size={18} color={'white'}>
                                부 칙(시행일) 이 약관은 2024년 10월 01일부터 시행합니다.
                            </P>
                        </FlexChild>
                    </FlexChild>
                    <FlexChild>
                        {
                            !props.signIn &&
                            <Footer />
                        }

                    </FlexChild>
                </VerticalFlex>
            </div>
        </Container>
    );

}

export default PrivacyPolicy;