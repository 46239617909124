import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import IdentityVerification from "./IdentityVerification";

function MemberNumberSignUpAndSignIn(props) {
    const location = useLocation();
    return (
        <IdentityVerification puffuOrder={location.state.type && location.state.type === "puffuOrder"} deviceCode={location.state.deviceCode && location.state.deviceCode} paymentData={location.state.paymentData && location.state.paymentData} payment={location.state.type && location.state.type === "payment"} storeCode={location.state && location.state.storeCode} mobileNumber={location.state && location.state.mobileNumber} />
    );
}

export default MemberNumberSignUpAndSignIn;