// modals/store/StoreDetailModal.js
import React, { useEffect, useRef, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import VerticalFlex from "layouts/flex/VerticalFlex";
import ModalBase from "modals/base/ModalBase";
import { useTranslation } from "react-i18next";
import P from "components/P";
import FlexChild from "layouts/flex/FlexChild";
import puffuIcon from "resources/img/puffu/puffuIcon.svg";
import customMarker from "resources/img/puffu/main/marker.svg"; // 커스텀 마커 이미지 임포트
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import Dummy from "components/Dummy";

const StoreDetailModal = NiceModal.create(({ store }) => {
    const { t } = useTranslation();
    const [withHeader, withFooter] = [false, false];
    const [width, height] = ["100%", "60%"];
    const withCloseButton = true;
    const modal = useRef();
    const clickOutsideToClose = true;
    const title = t("");
    const buttonText = t("close");

    // 복사 상태 관리
    const [copied, setCopied] = useState(false);
    const [copyError, setCopyError] = useState(false); // 복사 실패 상태

    // 지도 관련 refs
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null); // Kakao Map 객체
    const markerRef = useRef(null); // Marker 객체


    // 주소 복사 핸들러
    const handleCopyAddress = async () => {
        if (store && store.address_name) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                try {
                    await navigator.clipboard.writeText(store.address_name);
                    setCopied(true);
                    setCopyError(false);
                    // 2초 후에 복사 상태 초기화
                    setTimeout(() => setCopied(false), 2000);
                } catch (err) {
                    
                    setCopyError(true);
                    setCopied(false);
                    setTimeout(() => setCopyError(false), 2000);
                }
            } else {
                // Clipboard API 미지원 시 폴백 처리
                const textArea = document.createElement("textarea");
                textArea.value = store.address_name;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        setCopied(true);
                        setCopyError(false);
                        setTimeout(() => setCopied(false), 2000);
                    } else {
                        throw new Error("복사 명령 실패");
                    }
                } catch (err) {
                    
                    setCopyError(true);
                    setCopied(false);
                    setTimeout(() => setCopyError(false), 2000);
                }
                document.body.removeChild(textArea);
            }
        }
    };

    // 지도 초기화
    useEffect(() => {
        if (store && store.y && store.x && window.kakao && window.kakao.maps) {
            const kakao = window.kakao;
            const container = mapContainerRef.current;
            const storePosition = new kakao.maps.LatLng(parseFloat(store.y), parseFloat(store.x));

            const options = {
                center: storePosition,
                level: 3, // 확대 레벨
            };

            // 지도 생성
            mapRef.current = new kakao.maps.Map(container, options);

            // 커스텀 마커 이미지 설정
            const markerImageSize = new kakao.maps.Size(24, 35); // 마커 이미지 크기
            const markerImage = new kakao.maps.MarkerImage(customMarker, markerImageSize);

            // 마커 생성
            markerRef.current = new kakao.maps.Marker({
                position: storePosition,
                image: markerImage,
            });

            // 마커 지도에 표시
            markerRef.current.setMap(mapRef.current);

            // 마커 클릭 시 정보창 표시 (선택 사항)
            // kakao.maps.event.addListener(markerRef.current, 'click', function() {
            //     const infowindow = new kakao.maps.InfoWindow({
            //         content: `<div style="padding:5px;">${store.place_name}</div>`
            //     });
            //     infowindow.open(mapRef.current, markerRef.current);
            // });
        }

        // 컴포넌트 언마운트 시 지도 정리
        return () => {
            if (markerRef.current) {
                markerRef.current.setMap(null);
            }
            if (mapRef.current) {
                mapRef.current = null;
            }
        };
    }, [store]);

    // 네이버 지도 네비게이션 URL 생성
    const getNaverNavigationUrl = () => {
        const storeLat = parseFloat(store.y);
        const storeLng = parseFloat(store.x);
        const storeName = encodeURIComponent(store.place_name);
        const address = encodeURIComponent(store.address_name);
        const appName = encodeURIComponent("YourAppName"); // 앱 이름으로 변경

        // 네이버 지도 앱을 통한 네비게이션 URL
        const naverAppUrl = `nmap://route/car?dlat=${storeLat}&dlng=${storeLng}&dname=${storeName}&appname=${appName}`;

        // 웹 브라우저에서 네이버 지도 네비게이션 URL
        const naverWebUrl = `https://map.naver.com/v5/entry/driving/${storeLat},${storeLng}/${storeName}`;

        return { appUrl: naverAppUrl, webUrl: naverWebUrl };
    };

    // 카카오 지도 네비게이션 URL 생성
    const getKakaoNavigationUrl = () => {
        const storeLat = parseFloat(store.y);
        const storeLng = parseFloat(store.x);
        const storeName = encodeURIComponent(store.place_name);

        // 카카오 지도 앱을 통한 네비게이션 URL
        // sp (출발지) 생략 시 현재 위치 사용
        const kakaoAppUrl = `kakao://route?sp=0,0&ep=${storeLat},${storeLng}&by=car`;

        // 웹 브라우저에서 카카오 지도 네비게이션 URL
        const kakaoWebUrl = `https://map.kakao.com/link/to/${storeName},${storeLat},${storeLng}`;

        return { appUrl: kakaoAppUrl, webUrl: kakaoWebUrl };
    };
    

    return (
        <ModalBase
            slideUp
            ref={modal}
            width={width}
            height={height}
            withHeader={withHeader}
            withFooter={withFooter}
            withCloseButton={withCloseButton}
            clickOutsideToClose={clickOutsideToClose}
            title={title}
            buttonText={buttonText}
            overflow={"visible"}
            backgroundColor={"var(--bg-color)"}
        >
            {store ? (
                <VerticalFlex>
                    <FlexChild height={"300px"} width={"100%"}>

                        <div
                            ref={mapContainerRef}
                            style={{ width: "100%", height: "100%" }}
                        ></div>
                    </FlexChild>
                    <FlexChild>
                        <P weight={700} size={24} color={"white"}>{store.place_name}</P>
                        <P
                            color={"white"}
                            textDecoration={"underline"}
                            onClick={handleCopyAddress}
                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} // 기본 스타일 (추가/수정 가능)
                        >
                            {store.address_name}{"(복사하기)"}
                        </P>
                        {copied && <P color={"green"}>{t("주소가 복사되었습니다!")}</P>}
                        {copyError && <P color={"red"}>{t("주소 복사에 실패했습니다.")}</P>}
                    </FlexChild>
                    {/* 네비게이션 버튼 추가 */}
                    <FlexChild>
                        {/* 네이버 네비게이션 버튼 */}
                        <HorizontalFlex>
                            <FlexChild justifyContent={"center"}>
                                <a
                                    href={getNaverNavigationUrl().appUrl}
                                    onClick={(e) => {
                                        // 만약 앱이 설치되지 않은 경우 웹 URL로 리디렉션
                                        const timeout = setTimeout(() => {
                                            window.location.href = getNaverNavigationUrl().webUrl;
                                        }, 1000);
                                        // 사용자가 앱을 연다면 timeout을 취소
                                        window.onblur = () => {
                                            clearTimeout(timeout);
                                        };
                                    }}
                                    style={{ fontWeight: "bold", textDecoration: "none", color: "white", backgroundColor: "#03C75A", padding: "10px 20px", borderRadius: "5px" }}
                                >
                                    {t("네이버 지도")}
                                </a>
                            </FlexChild>
                            <FlexChild justifyContent={"center"}>
                                {/* 카카오 네비게이션 버튼 */}
                                <a
                                    href={getKakaoNavigationUrl().appUrl}
                                    onClick={(e) => {
                                        // 만약 앱이 설치되지 않은 경우 웹 URL로 리디렉션
                                        const timeout = setTimeout(() => {
                                            window.location.href = getKakaoNavigationUrl().webUrl;
                                        }, 1000);
                                        // 사용자가 앱을 연다면 timeout을 취소
                                        window.onblur = () => {
                                            clearTimeout(timeout);
                                        };
                                    }}
                                    style={{ textDecoration: "none", color: "black", fontWeight: "bold", backgroundColor: "#FFE812", padding: "10px 20px", borderRadius: "5px" }}
                                >
                                    {t("카카오 맵")}
                                </a>
                            </FlexChild>
                        </HorizontalFlex>
                    </FlexChild>

                </VerticalFlex>
            ) : (
                <P>매장 정보를 불러오는 중...</P>
            )}
        </ModalBase>
    );
});

export default StoreDetailModal;
