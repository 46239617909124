import NiceModal from "@ebay/nice-modal-react";

import P from "components/P";
import ModalBase from "modals/base/ModalBase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import { HOST } from "routes/front/main/WebSocketContainer";

const ReceiptDetailModal = NiceModal.create(props => {
  const { t } = useTranslation();
  const [withHeader, withFooter] = [false, false];
  const [width, height] = ["80%", "60%"];
  const withCloseButton = true;
  const modal = useRef();
  const clickOutsideToClose = true;
  const title = t("");
  const buttonText = t("close");

  return (
    <ModalBase
      ref={modal}
      width={width}
      height={height}
      withHeader={withHeader}
      withFooter={withFooter}
      withCloseButton={withCloseButton}
      clickOutsideToClose={clickOutsideToClose}
      title={title}
      buttonText={buttonText}
      overflow={"visible"}
      borderRadius={"25px"}
      onClose={props.onClose}
    >
      <P>sdf</P>
    </ModalBase>
  );
});

export default ReceiptDetailModal;
