import { requester } from "App";
import Dummy from "components/Dummy";
import P from "components/P";
import BackButton from "components/buttons/BackButton";
import CustomButton from "components/buttons/CustomButton";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { AuthContext } from "providers/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatLocalDate } from "shared/utils/Utils";

function ReceiptDetail() {

    const location = useLocation();
    const { id } = useParams();
    const [orderList, setOrderList] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (location) {
            setOrderList(location.state.data.orderProducts);
        }
    }, [location])
    const onReviewClick = (product) => {

        navigate(`/ws/${id}/mobile/receipt/review/${product.id}`, { state: { product: product } });

    }
    return (
        <div style={{ height: "95vh" }}>
            <div style={{ backgroundColor: "#F0F1F3" }}>
                <Dummy height={"50px"} />
                <VerticalFlex gap={10} padding={10}>
                    <FlexChild justifyContent={"center"}>
                        <P size={16} weight={"bold"}>구입내역상세</P>
                    </FlexChild>

                    {
                        orderList &&
                        orderList.map((data, index) => (
                            <FlexChild backgroundColor={"white"}>
                                <VerticalFlex gap={15}>
                                    <FlexChild width={"100%"} height={"1px"} backgroundColor={"#D9D9D9"} />
                                    <FlexChild height={"max-content"}>

                                        <HorizontalFlex>
                                            <FlexChild width={"30%"}>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}>
                                                    <img src={data && data.productImg} width={"60%"} />
                                                </div>
                                            </FlexChild>
                                            <FlexChild>
                                                <VerticalFlex>
                                                    <FlexChild>
                                                        <Center textAlign={"left"} width={"100%"}>
                                                            <P>{formatLocalDate(data.createDateTime)} 관저점</P>
                                                        </Center>
                                                    </FlexChild>
                                                    <FlexChild>
                                                        <Center textAlign={"left"} width={"100%"}>
                                                            <P>{data.productName} x{data.quantity}</P>
                                                        </Center>
                                                    </FlexChild>
                                                    <FlexChild>
                                                        <Center textAlign={"left"} width={"100%"}>
                                                            <P font={"gmarketSansBold"}>{data.price.toLocaleString()}원</P>
                                                        </Center>
                                                    </FlexChild>
                                                </VerticalFlex>
                                            </FlexChild>
                                            <FlexChild>
                                                <Center>
                                                    <CustomButton text={"리뷰작성"} onClick={() => onReviewClick(data)} disabled={data.reviewYn} />
                                                </Center>
                                            </FlexChild>
                                        </HorizontalFlex>
                                    </FlexChild>
                                    <FlexChild width={"100%"} height={"1px"} backgroundColor={"#D9D9D9"} />
                                </VerticalFlex>
                            </FlexChild>
                        ))
                    }
                    <FlexChild>
                        <P weight={"bold"}>총 결제금액 : {location && location.state.data.amount}</P>
                    </FlexChild>
                </VerticalFlex>
                <Dummy height={"12vh"} />
            </div>

        </div >
    );
}

export default ReceiptDetail;