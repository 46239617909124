import BackButton from "components/buttons/BackButton";
import Dummy from "components/Dummy";
import P from "components/P";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import { AuthContext } from "providers/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import homeIcon from "resources/img/puffu/homeIcon.svg";
import mypageIcon from "resources/img/puffu/mypage.svg";
import puffuorderIcon from "resources/img/puffu/puffuorder.svg";
import MobileNumberSignIn from "routes/account/signIn/MobileNumberSignIn";

function MobileLayout(props) {
    const { userId } = useContext(AuthContext);
    const { id } = useParams();
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState("main");
    const navigate = useNavigate();
    const [headerHeight, setHeaderHeight] = useState("50px");
    const { t } = useTranslation();



    useEffect(() => {

        const path = location.pathname.split("/").pop();
        
        
        if (path) {
            setSelectedMenu(path === "mobile" ? "mypage" : path);
            if(path === "withdrawal" ){
                setSelectedMenu("mypage");
            }else if(path === "receipt" ){
                setSelectedMenu("mypage");
            }else if(location.pathname.includes("review")){
                setSelectedMenu("review");
            }
        }
    }, [location.pathname, id]);

    const handleMenuClick = (menu) => {
        
        setSelectedMenu(menu);
        navigate(`/ws/${id}/mobile/${menu}`);
    };
    

    const FooterMenu = ({ icon, label, menu }) => {
        const isSelected = selectedMenu === menu;
        
        const iconStyle = {
            width: "30px",
            height: "30px",
            opacity: isSelected ? 1 : 0.6,
            filter: isSelected ? "none" : "grayscale(100%)"
        };
        const textStyle = isSelected ? "rgba(115, 255, 138, 1)" : "rgba(255, 255, 255, 0.4)";
        return (
            <FlexChild>
                <div
                    style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "70px" }}
                    onClick={() => handleMenuClick(menu)}
                >
                    <img src={icon} style={iconStyle} alt={label} />
                    <Dummy height={"15px"} />
                    <P color={textStyle}>{label}</P>
                </div>
            </FlexChild>
        );
    };

    return (
        <div style={{ backgroundColor: "var(--bg-color)" }}>
            {userId ? (
                <div>

                    {
                        selectedMenu !== "main" &&
                        <div
                            style={{
                                position: "fixed",
                                top: 0,
                                zIndex: 99,
                                width: "100%",
                                height: headerHeight,
                                backgroundColor: "var(--bg-color)"
                            }}
                        >
                            <div style={{ width: "90%", margin: "0 auto", height: headerHeight }}>
                                <HorizontalFlex>
                                    <FlexChild width={30}>
                                        {headerHeight === "50px" ? (
                                            <BackButton alignItems={"flex-start"} color="white" />
                                        ) : null}
                                    </FlexChild>
                                    <FlexChild justifyContent={"center"}>
                                        <P font={"GmarketSansBold"} color={"white"} size={20}>{t(selectedMenu)}</P>
                                        <Dummy width={"30px"} />
                                    </FlexChild>
                                </HorizontalFlex>
                            </div>
                            <div style={{ width: "100%", height: "2px", backgroundColor: "black" }} />
                        </div>
                    }

                    <Outlet />
                    <div
                        style={{
                            position: "fixed",
                            bottom: 0,
                            zIndex: 99,
                            width: "100%",
                            height: "10vh",
                            backgroundColor: "var(--bg-color)",
                            borderTop: "1.5px solid black"
                        }}
                    >
                        <HorizontalFlex>
                            <FooterMenu label="메인" menu="main" icon={homeIcon} />
                            <FooterMenu label="매장찾기" menu="storeList" icon={puffuorderIcon} />
                            <FooterMenu label="내 정보" menu="mypage" icon={mypageIcon} />
                        </HorizontalFlex>
                    </div>
                </div>
            ) : (
                <MobileNumberSignIn storeCode={id} />
            )}
        </div>
    );
}

export default MobileLayout;