// StoreList.js
import VerticalFlex from 'layouts/flex/VerticalFlex';
import FlexChild from 'layouts/flex/FlexChild';

import StoreListTab from './StoreListTab';
import FavoritesTab from './FavoritesTab';
import TabView from 'components/tabview/TabView';


function StoreList() {
    const tabs = {
        store: {
            label: '매장 리스트',
            content: <StoreListTab />,
        },
        favorite: {
            label: '즐겨찾기',
            content: <FavoritesTab />,
        },
    };

    return (
        <VerticalFlex gap={10} backgroundColor={'var(--bg-color)'}>
            <FlexChild height={"50px"} />
            <FlexChild>
                <TabView tabs={tabs} />
            </FlexChild>
            <FlexChild height={'10vh'} />
        </VerticalFlex>
    );
}

export default StoreList;
