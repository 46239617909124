import { createSlice } from '@reduxjs/toolkit';

export const PaymentReducer = createSlice({
    name: 'payment',
    initialState: {
        paymentUrl: null  
    },
    reducers: {
        
        setPaymentUrl: (state, action) => {
            state.paymentUrl = action.payload;
        },
        
        resetPaymentUrl: (state) => {
            state.paymentUrl = null;
        }
    }
});