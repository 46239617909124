

import Dummy from "components/Dummy";
import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext } from "react";
import puffu404Icon from "resources/img/puffu/404/puffu404Icon.png";

function Error404Page() {
    const { isMobile } = useContext(BrowserDetectContext);



    
    return (
        <Container backgroundColor={"white"}>
            {
                isMobile
                    ?
                    <Container maxWidth={1200} height={"100vh"}>
                        <VerticalFlex gap={30}>
                            <FlexChild justifyContent={"flex-end"}>
                                {/* <img src={puffu404Icon} width={"20%"} /> */}
                            </FlexChild>
                            <FlexChild height={"max-content"}>
                                <P weight={700} size={48} color={"black"}>404</P>
                                <P color={"black"} weight={600} size={"3vw"}>
                                    죄송합니다.
                                </P>
                                <P color={"black"} weight={600} size={"3vw"}>
                                    요청하신 페이지에 오류가 발생 하였습니다.
                                </P>
                            </FlexChild>
                            <FlexChild justifyContent={"flex-start"}>
                                <P color={"black"} weight={600} size={"2.5vw"}>
                                    오류가 계속 발생하는 경우
                                </P>
                                <P color={"black"} weight={600} size={"2.5vw"}>
                                    관리자에게 문의하시기 바랍니다.
                                </P>
                            </FlexChild>

                        </VerticalFlex>
                    </Container>
                    :
                    <Container maxWidth={1200} height={"100vh"}>
                        <VerticalFlex gap={30}>
                            <FlexChild justifyContent={"flex-end"}>
                                {/* <img src={puffu404Icon} width={"11%"} /> */}
                            </FlexChild>
                            <FlexChild>
                                <P weight={700} size={48} color={"black"}>404</P>
                                <P color={"black"} weight={600} size={"1.7vw"}>
                                    죄송합니다.
                                </P>
                                <P color={"black"} weight={600} size={"1.7vw"}>
                                    요청하신 페이지에 오류가 발생 하였습니다.
                                </P>
                            </FlexChild>
                            <FlexChild justifyContent={"flex-start"}>
                                <P color={"black"} weight={600} size={"1.1vw"}>
                                    오류가 계속 발생하는 경우
                                </P>
                                <P color={"black"} weight={600} size={"1.1vw"}>
                                    관리자에게 문의하시기 바랍니다.
                                </P>
                                <Dummy height={"20px"} />
                            </FlexChild>

                        </VerticalFlex>
                    </Container>
            }
        </Container>
    );
}


export default Error404Page;