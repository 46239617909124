import { Container } from "@mui/material";
import { requester } from "App";
import clsx from "classnames";
import CustomButton from "components/buttons/CustomButton";
import Dummy from "components/Dummy";
import P from "components/P";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { AuthContext } from "providers/AuthProvider";
import { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { removeLocalStorage } from "shared/utils/Utils";
import style from "./MemberWithdrawal.module.css";

function MemberWithdrawal() {
    const passwordRef = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const { userName, userId } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onWithdrawalClick = () => {
            if (isChecked) {
                let userData = { id: userId }
                requester.memberWithdrawal(userData, (result) => {
                    // alert("탈퇴가 정상적으로 처리되었습니다")
                    
                    // removeLocalStorage("token");
                    // window.location.replace(`/ws/${id}/mobile/main`);
                })
            } else {
                toast.error("동의를 하지 않으면 회원탈퇴가 불가능합니다.", {
                    autoClose: 1000, position: toast.POSITION.BOTTOM_CENTER
                });
            }
    }
    const btnStyle = {
        backgroundColor: "var(--main-color)",
        border: "none",
        color: "black",
        fontWeight: 900
    }
    return (
        <Container height={"90vh"} backgroundColor={""}>
            <Dummy height={"50px"} />
            <VerticalFlex gap={20}>
                <FlexChild height={"max-content"} padding={10}>
                    <div style={{ border: "1px solid white", padding: "10px",borderRadius: "5px" }}>

                        <Center>
                            <P size={24} weight={900} color={"white"}>주의사항</P>
                        </Center>
                        <Center textAlign={"left"} width={"100%"} >
                            <P weight={900} color={"white"}>※ 탈퇴시 서비스 이용이 불가능하니 신중히 선택해주세요</P>
                            <Dummy height={"10px"} />
                            <P color={"white"}>- 소비자 보호에 관한 법률에 따라 계약 또는 청약철회에 관한 기록 , 대금결제 및 재화 등의 공급에 관한 기록을 5년동안 보존됩니다.</P>
                            <Dummy height={"10px"} />
                            <P color={"white"}>- 회원탈퇴에 대한 처리는 즉시 이뤄집니다</P>
                            <Dummy height={"10px"} />
                            <P color={"white"}>- 관련 문의사항은 puffudev@gmail.com 으로 문의 바랍니다.</P>
                            <Dummy height={"10px"} />
                        </Center>
                    </div>
                </FlexChild>
                <FlexChild>
                    <HorizontalFlex padding={"10px"}>
                        <FlexChild width={"max-content"}>

                            <Center>
                                <Dummy height={"3px"} />
                                <P color={"white"} size={20} weight={"bold"}>상기 내용에 동의합니다 </P>
                            </Center>
                        </FlexChild>
                        <FlexChild>
                            <input
                                type="checkbox"
                                id="agreement"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                style={{ display: "none" }}
                            />
                            <label htmlFor="agreement" className={clsx(style.checkmark1, { [style.animated]: isChecked })}></label>
                        </FlexChild>
                    </HorizontalFlex>


                </FlexChild>
                <FlexChild height={"1.5px"} backgroundColor={"#ddd"} />
                <FlexChild padding={10}>
                    <CustomButton text={"회원탈퇴"} style={btnStyle} onClick={onWithdrawalClick} />
                </FlexChild>


            </VerticalFlex>
        </Container>
    );
}

export default MemberWithdrawal;