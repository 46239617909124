// TabView.js
import { useState } from 'react';
import style from './TabView.module.css';

function TabView({ tabs }) {
  const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);

  return (
    <div>
      {/* Tab Navigation */}
      <div className={style.tabContainer}>
        {Object.keys(tabs).map((tabKey) => (
          <div
            key={tabKey}
            className={`${style.tab} ${activeTab === tabKey ? style.activeTab : ''}`}
            onClick={() => setActiveTab(tabKey)}
          >
            {tabs[tabKey].label}
          </div>
        ))}
      </div>
      {/* Active Tab Content */}
      <div>{tabs[activeTab].content}</div>
    </div>
  );
}

export default TabView;
