import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AuthContext } from "providers/AuthProvider";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CartReducer } from "shared/redux/reducers/shopping/CartReducer";
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
export const OutletContext = createContext();

function WebSocketContainer() {
    const { id } = useParams();
    const [stompClient, setClient] = useState();
    const navigate = useNavigate();
    const itemDispensingModal = useModal("itemDispensing");
    const waitForItemCollectionModal = useModal('waitForItemCollection');
    let deviceCodeData = null;
    
    const { userId } = useContext(AuthContext);
    const dispatch = useDispatch();


    useEffect(() => {
        let Sock = new SockJS(`https://muin.shop:8086/api/ws`);
        
        setClient(over(Sock));
    }, []);


    useEffect(() => {
        if (stompClient) {
            stompClient.connect({}, onConnected, onError);
        }
    }, [stompClient]);

    const onConnected = () => {
        stompClient.subscribe(`/chatroom/${id}`, onMessageReceived);
    }
    const onMessageReceived = (payload) => {
        
        var payloadData = JSON.parse(payload.body);
        
        if (payloadData.code === "waitingForProducts") {
            
            if (window.BRIDGE && window.BRIDGE.getDeviceCode) {
                let deviceCode = window.BRIDGE.getDeviceCode();    
                if (deviceCode === payloadData.deviceCode) {
                    NiceModal.show("waitingForProducts", { data: payloadData.userId, storeCode: payloadData.roomId });
                }
            }

        }
        if (payloadData.code === "order") {
            
            if (window.BRIDGE && window.BRIDGE.sendOut) {
                if (window.BRIDGE && window.BRIDGE.getDeviceCode) {
                    let deviceCode = window.BRIDGE.getDeviceCode();
                    deviceCodeData = payloadData.deviceCode;
                    if (deviceCode === payloadData.deviceCode) {
                        navigate(`/ws/${id}/main/receiveItem`)
                        dispatch(CartReducer.actions.reset());
                    } else {
                        
                    }
                }
                const jsonData = payloadData.content;
                
                const jsonString = JSON.stringify(jsonData);
                const base64EncodedString = btoa(jsonString);
                window.BRIDGE.sendOut(base64EncodedString);
            } else {
                
            }
        }
        if (payloadData.code === "unsetOperating") {
            if (window.BRIDGE && window.BRIDGE.getDeviceCode) {
                let deviceCode = window.BRIDGE.getDeviceCode();

                
                let deviceCodeData = payloadData.deviceCode;
                
                
                if (deviceCode === deviceCodeData) {
                    
                    itemDispensingModal.remove();
                    
                } else {
                    waitForItemCollectionModal.remove();
                }
            }
        }

    }
    const onError = (err) => {
        
    }

    // 웹소켓 서버로 메시지 보내기
    const sendMessage = (message) => {
        // 적절한 목적지 주소와 보낼 메시지를 설정
        const destination = `/app/sendMessage/${id}`;
        // stompClient.send 메소드를 사용하여 메시지 전송
        stompClient.send(destination, {}, JSON.stringify({ content: message }));
    }

    const unsubscribe = () => {
        stompClient.unsubscribe();
    }

    return (
        <OutletContext.Provider value={{ id, sendMessage, unsubscribe }}>
            <Outlet />
        </OutletContext.Provider>

    );
}

export default WebSocketContainer;

