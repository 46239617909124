import logo from "resources/img/puffu/puffu.png"
import reverseLogo from "resources/img/puffu/puffuReaverse.png"
import downArrow from "resources/img/puffu/downArrow.png"
import eye from "resources/img/puffu/eye.png"
import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Dummy from "components/Dummy";
import style from "./Brand.module.css"
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext } from "react"
import Footer from "../footer/Footer";



function Brand() {

    const { isMobile } = useContext(BrowserDetectContext);

    return (
        <Container backgroundColor={'#282828'}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <VerticalFlex maxWidth={1200} alignItems={'center'} justifyContent={'center'}>
                    <FlexChild maxWidth={1200} padding={'2% 5% 0 5%'} backgroundColor={'#282828'} position={'fixed'} top={'0%'}>
                        <HorizontalFlex justifyContent={'space-between'} alignItems={'center'}>
                            <FlexChild width={'auto'} marginRight={'1.5%'}>
                                <img src={logo} style={{ width: 'min(80px,9vw)' }} />
                            </FlexChild>
                            <HorizontalFlex justifyContent={'flex-end'}>
                                <FlexChild width={'auto'} marginRight={'5%'}>
                                    <P size={'max(14px,1vw)'} color={'white'} weight={'700'} lineHeight={'1.5vh'}>
                                        브랜드 소개
                                    </P>
                                </FlexChild>
                                <FlexChild width={'auto'}>
                                    <P size={'max(14px,1vw)'} color={'white'} weight={'700'} lineHeight={'1.5vh'}>
                                        차별성
                                    </P>
                                </FlexChild>
                            </HorizontalFlex>
                        </HorizontalFlex>
                    </FlexChild>
                    <FlexChild padding={'15% 0 5.5% 0'} maxWidth={'80%'}>
                        <HorizontalFlex>
                            <FlexChild width={'30%'} justifyContent={'center'}>
                                <div
                                    className={style.radiusBoxGreen}
                                >
                                    <P size={'min(30px,3.5vw)'} color={'#282828'} weight={'900'}>
                                        무인매장
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild width={'20%'} justifyContent={'center'}>
                                <div
                                    className={style.radiusBox}
                                >
                                    <P size={'min(30px,3.5vw)'} color={'white'} weight={'700'}>
                                        자판기
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild width={'35%'} justifyContent={'center'}>
                                <div
                                    className={style.radiusBox}
                                >
                                    <P size={'min(30px,3.5vw)'} color={'white'} weight={'700'}>
                                        무인매장 결제
                                    </P>
                                </div>
                            </FlexChild>
                        </HorizontalFlex>
                        <Dummy height={'1.5vh'} />
                        <HorizontalFlex justifyContent={'space-around'}>
                            <FlexChild width={'30%'} justifyContent={'center'}>
                                <div
                                    className={style.radiusBox}
                                >
                                    <P size={'min(30px,3.5vw)'} color={'white'} weight={'700'}>
                                        무인 솔루션
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild width={'25%'} justifyContent={'center'}>
                                <div
                                    className={style.radiusBoxGreen}
                                >
                                    <P size={'min(30px,3.5vw)'} color={'white'} weight={'700'}>
                                        <img src={reverseLogo} style={{ width: 'min(120px,12vw)' }} />
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild width={'25%'} justifyContent={'center'}>
                                <div
                                    className={style.radiusBox}
                                >
                                    <P size={'min(30px,3.5vw)'} color={'white'} weight={'700'}>
                                        키오스크
                                    </P>
                                </div>
                            </FlexChild>
                        </HorizontalFlex>
                    </FlexChild>
                    <FlexChild padding={'5% 0'} maxWidth={'80%'}>
                        <img src={downArrow} style={{ width: 'min(80px,7vw)' }} />
                    </FlexChild>
                    <FlexChild padding={' 0 0 5% 0'} maxWidth={'80%'}>
                        <P size={'min(72px,5.8vw)'} color={'#73FF8A'} weight={'700'} letterSpacing={'1px'}>
                            핸드폰 하나로 무인매장 관리
                        </P>
                    </FlexChild>
                    <FlexChild maxWidth={'65%'} padding={'0 0 8% 0'}>
                        <HorizontalFlex alignItems={'flex-end'}>
                            <VerticalFlex alignItems={'flex-start'}>
                                <FlexChild width={'auto'} >
                                    <P size={'min(72px,5.8vw)'} color={'#282828'} weight={'900'} webkitTextStroke={'0.025vw white'} lineHeight={'1'}>
                                        이게
                                    </P>
                                </FlexChild>
                                <FlexChild width={'auto'}>
                                    <P size={'min(72px,5.8vw)'} color={'#282828'} weight={'900'} webkitTextStroke={'0.025vw white'} lineHeight={'1'}>
                                        가능해?
                                    </P>
                                </FlexChild>
                            </VerticalFlex>
                            <FlexChild width={'auto'}>
                                <img src={eye} style={{ width: 'min(180px,12vw)' }} />
                            </FlexChild>
                        </HorizontalFlex>
                    </FlexChild>
                    <FlexChild maxWidth={'65%'} padding={'0 0 8% 0'}>
                        <HorizontalFlex alignItems={'center'}>
                            <FlexChild>
                                <div
                                    className={style.box}
                                >
                                    <P size={'min(24px,2.4vw)'} color={'white'} lineHeight={'1'}>
                                        핸드폰으로
                                    </P>
                                    <P size={'min(24px,2.4vw)'} color={'white'} lineHeight={'1'}>
                                        어디서나 쉽게
                                    </P>
                                    <Dummy height={'1vh'} />
                                    <P size={'min(28px,2.8vw)'} color={'white'} weight={'700'}>
                                        무인매장 관리
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild width={'1%'} position={'relative'}>
                                <div
                                    className={style.plus}
                                    style={{ left: isMobile ? '-300%' : '-100%' }}
                                >
                                    <P size={'min(24px,2.8vw)'} color={'black'} weight={'700'} lineHeight={'20px'}>
                                        +
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild>
                                <div
                                    className={style.boxWhite}
                                >
                                    <P size={'min(24px,2.4vw)'} color={'black'} lineHeight={'1'}>
                                        누구나 쉽게
                                    </P>
                                    <P size={'min(24px,2.4vw)'} color={'black'} lineHeight={'1'}>
                                        사용가능한 UI
                                    </P>
                                    <Dummy height={'1vh'} />
                                    <P size={'min(28px,2.8vw)'} color={'black'} weight={'900'}>
                                        키오스크
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild width={'1%'} position={'relative'}>
                                <div
                                    className={style.plus}
                                    style={{ left: isMobile ? '-300%' : '-100%' }}
                                >
                                    <P size={'min(24px,2.8vw)'} color={'black'} weight={'700'} lineHeight={'20px'}>
                                        +
                                    </P>
                                </div>
                            </FlexChild>
                            <FlexChild>
                                <div
                                    className={style.box}
                                >
                                    <P size={'min(24px,2.4vw)'} color={'white'} lineHeight={'1'}>
                                        성인 인증부터
                                    </P>
                                    <P size={'min(24px,2.4vw)'} color={'white'} lineHeight={'1'}>
                                        결제까지!
                                    </P>
                                    <Dummy height={'1vh'} />
                                    <P size={'min(28px,2.8vw)'} color={'white'} weight={'700'}>
                                        결제 시스템
                                    </P>
                                </div>
                            </FlexChild>
                        </HorizontalFlex>
                    </FlexChild>
                    <FlexChild padding={'0 0 5% 0'}>
                        <P size={'min(48px,3vw)'} color={'white'}>
                            여러 업체를 통해 관리했던 무인매장
                        </P>
                        <P size={'min(52px,3.5vw)'} color={'white'} weight={'700'}>
                            이제 푸푸 앱으로 한 번에 관리하세요!
                        </P>
                    </FlexChild>
                    <FlexChild>
                        <img src={logo} style={{ width: 'min(240px,27vw)' }} />
                    </FlexChild>
                    <FlexChild padding={'25% 0 0 0'}>
                        <Footer />
                    </FlexChild>
                </VerticalFlex>
            </div>



        </Container>
    )
}

export default Brand;