import NiceModal from "@ebay/nice-modal-react";
import AddressKrModal from "modals/address/kr/AddressKrModal";
import ConfirmModal from "modals/confirm/ConfirmModal";
import LogoutConfirmModal from "modals/confirm/LogoutConfirmModal";
import PaymentRequestModal from "modals/payment/PaymentRequestModal";
import ReceiptDetailModal from "modals/receipt/ReceiptDetailModal";
import ShippingInfoAddKrModal from "modals/shippingInfo/ShippingInfoAddKrModal";

import NumberVerificationByPaymentModal from "modals/confirm/NumberVerificationByPaymentModal";
import NumberVerificationByPuffuOrderModal from "modals/confirm/NumberVerificationByPuffuOrderModal";
import NumberVerificationModal from "modals/confirm/NumberVerificationModal";
import StoreListModal from "modals/store/StoreListModal";
import StoreDetailModal from "modals/store/StoreDetailModal";

function ModalHandler() {
  
  NiceModal.register("paymentRequest", PaymentRequestModal);
  
  NiceModal.register("addressKr", AddressKrModal);
  NiceModal.register("receiptDetail", ReceiptDetailModal);
  NiceModal.register("confirm", ConfirmModal);
  NiceModal.register("numberVerification", NumberVerificationModal);
  NiceModal.register("numberVerificationByPayment", NumberVerificationByPaymentModal);
  NiceModal.register("numberVerificationByPuffuOrder", NumberVerificationByPuffuOrderModal);  
  NiceModal.register("logout", LogoutConfirmModal);
  NiceModal.register("shippingInfoAddKrModal", ShippingInfoAddKrModal);
  NiceModal.register("storeList", StoreListModal);
  NiceModal.register("storeDetail", StoreDetailModal);
}

export default ModalHandler;
