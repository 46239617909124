import NiceModal from "@ebay/nice-modal-react";
import Dummy from "components/Dummy";
import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AuthReducer } from "shared/redux/reducers/auth/AuthReducer";
import { OrderInfoReducer } from "shared/redux/reducers/shopping/OrderInfoReducer";
import { removeLocalStorage } from "shared/utils/Utils";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import { AuthContext } from "providers/AuthProvider";
import withdrawalIcon from "resources/img/puffu/withdrawal.svg";
import orderIcon from "resources/img/puffu/order.svg";


function Mypage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobile } = useContext(BrowserDetectContext);
    const { userName } = useContext(AuthContext);

    const onSignoutClick = () => {
        NiceModal.show("logout", {
            message: t("confirmLogout"),
            confirmText: t("confirm"),
            cancelText: t("cancel"),
            onConfirm: onConfirm,
            onCancel: onCancel
        });
    }

    const onConfirm = () => {
        removeLocalStorage("token");
        dispatch(AuthReducer.actions.reset());

        dispatch(OrderInfoReducer.actions.reset());

        window.location.replace(`/ws/${id}/mobile`);
    }

    const onCancel = () => {

    }
    const onWithdrawalClick = () => {
        navigate(`/ws/${id}/mobile/withdrawal`)
    }
    const onPurchaseorderClick = () => {
        navigate(`/ws/${id}/mobile/receipt`)
    }
    return (
        <Container height={"90vh"} backgroundColor={""}>
            <Dummy height={"50px"} />
            <VerticalFlex>
                <FlexChild height={"max-content"} width={"90%"} padding={"20px"}>
                    <HorizontalFlex>
                        <FlexChild>
                            <Center width={'100%'} textAlign={"left"}>
                                <P color={"white"} size={24} weight={700}>{userName} 님</P>
                            </Center>
                        </FlexChild>
                        <FlexChild justifyContent={"flex-end"} width={"max-content"}>
                            <P color={"white"} cursor onClick={onSignoutClick} weight={"bold"} font={"GmarketSans"} padding={"3px 7px"} border={"1px solid white"} borderRadius={"5px"}>로그아웃</P>
                        </FlexChild>
                    </HorizontalFlex>
                </FlexChild>
                <FlexChild height={"7px"} width={"100%"} backgroundColor={"black"} />
                <FlexChild padding={10} height={"max-content"}>
                    <div onClick={onPurchaseorderClick} style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer", width: "90%", gap: "15px" }}>
                        <img src={orderIcon} width={"25px"} />
                        <P size={24} color={"white"} weight={500}>구입 내역 조회</P>
                    </div>
                </FlexChild>
                <FlexChild padding={10}>
                    <div onClick={onWithdrawalClick} style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer", width: "90%", gap: "15px" }}>
                        <img src={withdrawalIcon} width={"25px"} />
                        <P size={24} color={"white"} weight={500}>회원탈퇴</P>
                    </div>
                </FlexChild>


            </VerticalFlex>
        </Container>
    );
}

export default Mypage;