import Error404Page from "components/404/Error404Page";
import { Route, Routes, useLocation } from "react-router-dom";
import MobileNumberSignIn from "routes/account/signIn/MobileNumberSignIn";
import MemberNumberSignUpAndSignIn from "routes/account/signUp/MemberNumberSignUpAndSignIn";
import MemberSignUp from "routes/account/signUp/MemberSignUp";
import VendingSignIn from "routes/front/main/vending/VendingSignIn";
import WebSocketContainer from "routes/front/main/WebSocketContainer";
import VendingComplete from "routes/front/mobile/complete/VendingComplete";

import { requester } from "App";
import { AuthContext } from "providers/AuthProvider";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import MemberWithdrawal from "routes/account/withdrawal/MemberWithdrawal";
import Brand from "routes/front/mobile/brand/Brand";
import Main from "routes/front/mobile/Main";
import MobileLayout from "routes/front/mobile/MobileLayout";
import Mypage from "routes/front/mobile/Mypage";
import PaymentLanding from "routes/front/mobile/payment/PaymentLanding";
import PaymentLandingGateway from "routes/front/mobile/payment/PaymentLandingGateway";
import PolicyPage from "routes/front/mobile/policy/PolicyPage";
import Receipt from "routes/front/mobile/receipt/Receipt";
import ReceiptDetail from "routes/front/mobile/receipt/ReceiptDetail";
import ProductReview from "routes/front/mobile/review/ProductReview";
import StoreList from "routes/front/mobile/store/StoreList";
import Payment2 from "routes/main/Payment2";
import PaymentComplete from "routes/main/PaymentComplete";
import PaymentGateway from "routes/main/PaymentGateway";
import { FavoriteReducer } from "shared/redux/reducers/shopping/FavoriteReducer";
import QrTest from "routes/main/test/QrTest";
import PrivacyPolicy from "routes/front/mobile/policy/PrivacyPolicy";
import PaymentFail from "routes/main/PaymentFail";
import PaymentHistoryGateway from "routes/front/mobile/payment/PaymentHistoryGateway";




function FrontRouter({ allowedRoles }) {
    const location = useLocation();
    const { isAuthenticated } = useContext(AuthContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated === true) {
            requester.findAllbyFavorites((result) => {
                if (result.code === 0) {
                    dispatch(FavoriteReducer.actions.refreshFavorites(result.data));
                }
            });
        } else {
            dispatch(FavoriteReducer.actions.reset());
        }
    }, [isAuthenticated]);

    return (
        <Routes location={location}>
            <Route path="*" element={<Error404Page />} />
            <Route path="vendingComplete" element={<VendingComplete />} />
            <Route path="mobileNumber" element={<MobileNumberSignIn />} />
            <Route path="memberAccess" element={<MemberNumberSignUpAndSignIn />} />
            <Route path="/signUp" element={<MemberSignUp />} />
            <Route path="/policy" element={<PolicyPage />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/qrtest" element={<QrTest />} />
            
            <Route path="/ws/:id" element={<WebSocketContainer />} >
            <Route path="paymentHistory" element={<PaymentHistoryGateway />} />
                <Route path="landing" element={<PaymentLanding />} />
                <Route path="landingGateWay" element={<PaymentLandingGateway />} />
                <Route path="vendingSignIn" element={<VendingSignIn />} />
                <Route path="mobile" element={<MobileLayout />}>
                    <Route path="main" element={<Main />} />
                    <Route path="storeList" element={<StoreList />} />
                    <Route path="mypage" element={<Mypage />} />
                    <Route path="withdrawal" element={<MemberWithdrawal />} />
                    <Route path="receipt">
                        <Route path="" element={<Receipt />} />
                        <Route path="detail/:orderId" element={<ReceiptDetail />} />
                        <Route path="review/:orderId" element={<ProductReview />} />
                    </Route>
                </Route>
                <Route path="payment">
                    <Route path="" element={<Payment2 />} />
                    <Route path="gateway" element={<PaymentGateway />} />
                    <Route path="complete" element={<PaymentComplete />} />
                    <Route path="fail" element={<PaymentFail />} />
                </Route>

            </Route>


        </Routes>
    );
}

export default FrontRouter;