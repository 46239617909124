import { createSlice } from "@reduxjs/toolkit";

export const FavoriteReducer = createSlice({
    name: "Favorites",
    initialState: {
        favorites: []
    },
    reducers: {
        toggleFavorite: (state, action) => {
            const { x, y } = action.payload;
            const existingIndex = state.favorites.findIndex(
                (favorite) => favorite.x === x && favorite.y === y
            );

            if (existingIndex >= 0) {
                // 이미 존재하면 제거
                state.favorites.splice(existingIndex, 1);
            } else {
                // 존재하지 않으면 추가
                state.favorites.push(action.payload);
            }
        },
        refreshFavorites: (state, action) => {
            state.favorites = action.payload;
        },
        reset: (state) => {
            state.favorites = [];
        }
    },
    extraReducers: {

    }
});
