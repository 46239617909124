import NiceModal from "@ebay/nice-modal-react";
import { requester } from "App";
import Dummy from "components/Dummy";
import P from "components/P";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import HorizontalFlex from "layouts/flex/HorizontalFlex";
import VerticalFlex from "layouts/flex/VerticalFlex";
import Center from "layouts/wrapper/Center";
import { AuthContext } from "providers/AuthProvider";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import activeStar from "resources/img/other/Star 1.svg";
import passiveStar from "resources/img/other/Star 2.svg";
import eyeIcon from "resources/img/puffu/main/eye.svg";
import qrIcon from "resources/img/puffu/main/qrIcon.svg";
import puffuIcon from "resources/img/puffu/puffuIcon.svg";
import rightIcon from "resources/img/puffu/right.png";
import { FavoriteReducer } from "shared/redux/reducers/shopping/FavoriteReducer";

function Main() {
    const { userName } = useContext(AuthContext);
    const { isMobile } = useContext(BrowserDetectContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const mapContainer = useRef(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nearbyStores, setNearbyStores] = useState(); // 가까운 매장 저장

    // iOS 브릿지 핸들러 존재 여부 확인
    const isIOS = !!window.webkit?.messageHandlers?.BRIDGE;

    useEffect(() => {
        if (userName === "01088888888") {
            // 특정 위치로 좌표 고정 (예: 서울 시청)
            setLatitude(37.5665);
            setLongitude(126.9780);
            setLoading(false);
        } else if (isIOS) {
            // 기존 iOS 위치 가져오기 로직
            if (window.webkit.messageHandlers.BRIDGE) {
                window.webkit.messageHandlers.BRIDGE.postMessage("requestLocation");
            } else {
                setError("iOS 브릿지 핸들러가 존재하지 않습니다.");
                setLoading(false);
            }
    
            window.handleLocationFromIOS = (lat, lon) => {
                setLatitude(lat);
                setLongitude(lon);
                setLoading(false);
            };
        } else {
            // 기존 브라우저 위치 가져오기 로직
            if (!navigator.geolocation) {
                setError("Geolocation을 지원하지 않는 브라우저입니다.");
                setLoading(false);
                return;
            }
    
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setLoading(false);
                },
                (error) => {
                    setError("위치 정보를 가져올 수 없습니다.");
                    setLoading(false);
                }
            );
        }
    }, [isIOS, userName]);

    // 지도와 매장 검색 초기화
    useEffect(() => {
        if (!loading && !error && latitude && longitude && window.kakao && window.kakao.maps) {
            const kakao = window.kakao;
            
    
            // userName에 따른 검색 키워드 설정
            const searchKeyword = userName === "01088888888" ? "하레하레" : "무인매장 푸푸";
    
            // 매장 검색
            const ps = new kakao.maps.services.Places();
            ps.keywordSearch(searchKeyword, function (data, status) {
                if (status === kakao.maps.services.Status.OK) {
                    const filteredStores = data.map((store) => {
                        const storeLat = parseFloat(store.y);
                        const storeLng = parseFloat(store.x);
                        const distance = calculateDistance(latitude, longitude, storeLat, storeLng);
                        return { ...store, distance };
                    });
    
                    // 가까운 순으로 정렬
                    const sortedStores = filteredStores.sort((a, b) => a.distance - b.distance);
                    setNearbyStores(sortedStores);
                } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
                    setError("주변에 해당 매장이 없습니다.");
                } else {
                    setError("매장 검색 중 오류가 발생했습니다.");
                }
            });
        }
    }, [loading, error, latitude, longitude, userName]);
    

    const onScanClick = () => {
        if (window.BRIDGE && window.BRIDGE.scan) {
            window.BRIDGE.scan();
        } else if (window.webkit.messageHandlers && window.webkit.messageHandlers.BRIDGE) {
            window.webkit.messageHandlers.BRIDGE.postMessage('scan');
        } else {

        }
    }

    // Haversine 공식을 사용하여 거리 계산
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // 지구 반경(km)
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // 거리 (km)
        return distance;
    };



    const StoreBox = ({ store }) => {
        const dispatch = useDispatch();
        const favorites = useSelector((state) => state.favorite.favorites);
        
        // 즐겨찾기 여부 확인
        const isFavorited = favorites.some(
            (favorite) => favorite.x === store.x && favorite.y === store.y
        );

        const handleClick = () => {
            // 모달을 열고 store 정보를 전달
            NiceModal.show("storeDetail", { store });
        };

        const toggleFavorite = (e) => {
            e.stopPropagation(); // 부모의 onClick 이벤트 전파 방지
            const data = {
                address: store.address_name,
                roadAddress: store.road_address_name,
                placeName: store.place_name,
                x: store.x,
                y: store.y,
            };

            requester.toggleFavorite(data, (result) => {
                if (result.code === 0) {
                    // 서버에서 반환한 업데이트된 즐겨찾기 목록으로 상태 갱신
                    dispatch(FavoriteReducer.actions.refreshFavorites(result.data));
                } else {
                    // 에러 처리
                    
                }
            });
        };
        const distanceInMeters = store.distance * 1000; // km를 m로 변환
        return (

            <div onClick={handleClick} style={{ position: "relative", border: "1.5px solid white", borderRadius: "5px", height: "120px" }}>
                <VerticalFlex>
                    <FlexChild height={"50%"}>
                        <Center>
                            <P weight={700} width={"23vw"} ellipsis color={"white"}>{store.place_name}</P>
                        </Center>
                    </FlexChild>
                    <FlexChild>
                        <P color={"white"}>
                            {distanceInMeters < 1000
                                ? `${distanceInMeters.toFixed(0)} m`
                                : `${store.distance.toFixed(2)} km`}
                        </P>
                    </FlexChild>
                </VerticalFlex>
                <div
                    onClick={toggleFavorite}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        color: 'white',
                        width: "max-content"
                    }}
                >
                    {isFavorited ? <img src={activeStar} /> : <img src={passiveStar} />}
                </div>
            </div>
        )
    }


        ;

    if (loading) return <p>위치 정보를 가져오는 중...</p>;
    // if (error) return <p style={{ color: "red" }}>{error}</p>;

    const findStoreClick = () => {
        navigate(`/ws/${id}/mobile/storeList`);
    }

    return (
        <Container height={"90vh"} backgroundColor={"var(--bg-color)"}>
            <VerticalFlex gap={30}>
                <FlexChild height={"50px"} width={"90%"}>
                    <HorizontalFlex>
                        <FlexChild>
                            <Center width={'100%'} textAlign={"left"}>
                                <P color={"white"} size={24} weight={700}>{userName} 님</P>
                            </Center>
                        </FlexChild>
                        <FlexChild width={"max-content"}>
                            <img src={qrIcon} onClick={onScanClick} />
                        </FlexChild>
                    </HorizontalFlex>
                </FlexChild>
                <FlexChild height={"max-content"}>
                    <div onClick={onScanClick} style={{ position: "relative", backgroundColor: "var(--main-color)", height: "120px", borderRadius: "10px", width: "95%" }}>
                        <img src={eyeIcon} style={{ position: "absolute", top: "-15px", left: "20px" }} />
                        <Center width={'100%'} >
                            <P color={"black"} size={24} weight={900}>QR코드 결제하기</P>
                        </Center>
                    </div>
                </FlexChild>
                <FlexChild height={"7px"} width={"100%"} backgroundColor={"black"} />
                <FlexChild height={"max-content"}>
                    <HorizontalFlex width={"90%"}>
                        <FlexChild>
                            <Center width={'100%'} textAlign={"left"}>
                                <P color={"white"} size={24} weight={700}>내 주변 매장</P>
                            </Center>
                        </FlexChild>
                        <FlexChild width={"max-content"}>
                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={findStoreClick}>
                                <P color={"white"}>더보기</P>
                                <Dummy width={"15px"} />
                                <img src={rightIcon} style={{ filter: "brightness(5000%)", width: "7px" }} />
                            </div>
                        </FlexChild>
                    </HorizontalFlex>
                </FlexChild>

                <FlexChild height={"max-content"} width={"90%"}>
                    <HorizontalFlex gap={10}>
                        {nearbyStores && nearbyStores.length > 0 ? nearbyStores.slice(0, 3).map((store, index) => (
                            <FlexChild key={index}>
                                <StoreBox store={store} />
                            </FlexChild>
                        ))
                            :
                            <Center height={"120px"}>
                                <P color={"white"}>주변에 매장이 없습니다</P>
                            </Center>
                        }
                    </HorizontalFlex>
                </FlexChild>
                <FlexChild width={"90%"} height={"150px"}>
                    <HorizontalFlex borderRadius={"5px"} backgroundColor={"black"}>
                        <FlexChild padding={"15px"}>
                            <Center width={"100%"} textAlign={"left"}>
                                <P weight={900} color={"var(--main-color)"} size={24}>푸푸 APP으로</P>
                                <P color={"white"} size={24}>편리하게 결제하세요</P>
                            </Center>
                        </FlexChild>
                        <FlexChild width={"25%"} padding={"0 10px 0 0"}>
                            <img src={puffuIcon} width={"90%"} />

                        </FlexChild>
                    </HorizontalFlex>
                </FlexChild>
            </VerticalFlex>
        </Container>
    );
}

export default Main;
