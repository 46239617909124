import axios from "axios";



class Requester {
  instance = null;
  constructor() {
    axios.defaults.withCredentials = true;
    this.instance = axios.create({
      baseURL: "https://muin.shop:8086/api",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      origin: "https://muin.shop:8086/",
      withCredentials: true,
    });
  }

  setAuthTokenHeader(token) {
    this.instance = axios.create({
      baseURL: "https://muin.shop:8086/api",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      origin: "https://muin.shop:8086/",
      withCredentials: true,
    });
  }
  // constructor() {
  //   axios.defaults.withCredentials = true;
  //   this.instance = axios.create({
  //     baseURL: "http://59.26.140.16:18000/api",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     origin: "http://59.26.140.16:18000/",
  //     withCredentials: true,
  //   });
  // }

  // setAuthTokenHeader(token) {
  //   this.instance = axios.create({
  //     baseURL: "http://59.26.140.16:18000/api",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: token,
  //     },
  //     origin: "http://59.26.140.16:18000/",
  //     withCredentials: true,
  //   });
  // }
  

  async get(url, data) {
    let instance = this.instance;
    let result = "";
    let params = "?" + new URLSearchParams(data).toString();
    try {
      return new Promise(function (resolve, reject) {
        instance.get(url + params)
          .then((res) => {
            result = res.data;
          })
          .catch((error) => {
            if (error.response) {
              result = error.response.data;
              // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
              if (error.response.status === 401) {
                // invalid token
                result.code = error.response.status;
              } else {

              }
            }
            else if (error.request) {
              // 요청이 이루어 졌으나 응답을 받지 못했습니다.
              // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
              // Node.js의 http.ClientRequest 인스턴스입니다.

            }
            else {
              // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.

            }
          })
          .finally(() => {
            resolve(result);
          });
      });
    } catch (e) {

    }
  }

  async post(url, data) {
    let instance = this.instance;
    let result = "";
    try {
      return new Promise(function (resolve, reject) {
        instance.post(url, JSON.stringify(data))
          .then((res) => {
            result = res.data;
          })
          .catch((error) => {
            if (error.response) {
              result = error.response.data;
              if (error.response.status === 401) {
                // invalid token
                result.code = error.response.status;
              } else {

              }
            }
            else if (error.request) {

            }
            else {

            }

          })
          .finally(() => {
            resolve(result);
          });
      });
    } catch (e) {

    }
  }

  async put(url, data) {
    let instance = this.instance;
    let result = "";
    try {
      return new Promise(function (resolve, reject) {
        instance.put(url, JSON.stringify(data))
          .then((res) => {
            result = res.data;
          })
          .catch((ex) => {
            result = ex;
          })
          .finally(() => {
            resolve(result);
          });
      });
    } catch (e) {

    }
  }

  async upload(data) {
    try {
      let promise = new Promise((resolve, reject) => {
        let result = "";
        this.instance
          .post("open/file/upload", data)
          .then((res) => {
            result = res.data;
          })
          .catch((ex) => {
            result = ex;
          })
          .finally(() => {
            resolve(result);
          });
      });
      let result = await promise;
      return result;
    } catch (e) {

    }
  }


  async memberNumberSignUp(data, callback) {
    callback(await this.post("/open/userAccount/memberNumberSignUp", data));
  }

  async userSignUp(data, callback) {
    callback(await this.post("/open/user/signUp", data));
  }
  async memberSignUp(data, callback) {
    callback(await this.post("/open/userAccount/memberSignUp", data));
  }


  async userDuplicateCheck(data, callback) {
    callback(await this.post("/open/userAccount/memberDuplicate", data));
  }
  async membermobileNumDuplicate(data, callback) {
    callback(await this.post("/open/userAccount/membermobileNumDuplicate", data));
  }
  async duplicateCheck(data, callback) {
    callback(await this.post("/open/userAccount/duplicate", data));
  }

  async passwordConfirm(data, callback) {
    callback(await this.post("/open/userAccount/passwordConfirm", data));
  }

  async updateUserInfo(data, callback) {
    callback(await this.post("/open/user/update", data));
  }

  async findByUser(data, callback) {
    callback(await this.post("/open/user/findById", data));
  }

  async userSignIn(data, callback) {
    callback(await this.post("login", data));
  }

  async findAllPurchaseOrder(data, callback) {
    callback(await this.post("member/order/findAll", data));
  }
  async findAllByOrderProduct(data, callback) {
    callback(await this.post("member/order/findAllByOrderProduct", data));
  }
  async changeOrderStatus(data, callback) {
    callback(await this.post("member/order/changeStatus", data));
  }
  async testChangeOrderStatus(data, callback) {
    callback(await this.post("open/order/changeStatus", data));
  }





  async searchByProductName(data, callback) {
    callback(await this.post("member/mapper/searchByProductName", data));
  }
  async searchByStoreProduct(data, callback) {
    callback(await this.post("open/vendor/product/searchByStoreProduct", data));
  }
  async useYnConfirm(data, callback) {
    callback(await this.post("/open/userAccount/useYnConfirm", data));
  }
  async memberWithdrawal(data, callback) {
    callback(await this.post("/open/userAccount/memberWithdrawal", data));
  }
  async findAllByConfirmYn(data, callback) {
    callback(await this.post("manager/storeManagement/findAllByConfirmYn", data));
  }
  async storeConfirm(data, callback) {
    callback(await this.post("manager/storeManagement/storeConfirm", data));
  }

  async userNumberSignIn(data, callback) {
    callback(await this.post("/mobileNumberLogin", data));
  }
  async findAllbyFavorites(callback) {
    callback(await this.get("/favorite/findAll"));
  }
  async toggleFavorite(data, callback) {
    callback(await this.post("/favorite/toggleFavorite", data));
  }
  async createReview(data, callback) {
    callback(await this.post("review/create", data));
  }




  // -------------- 테스트 결제용
  async testPayment(data, callback) {
    callback(await this.post("open/payment/testPayment", data));
  }
  //웹소켓 통신
  async testOrder(data, callback) {
    callback(await this.post("open/order/testOrder", data));
  }
  async testPaymentRequest(data, callback) {
    callback(await this.post("open/order/testCreateRequest", data));
  }
  async findByPaymentHistory(data, callback) {
    callback(await this.post("open/paymentHistory/find", data));
  }



}

export default Requester;