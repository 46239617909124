import CustomButton from "components/buttons/CustomButton";
import Container from "layouts/container/Container";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthReducer } from "shared/redux/reducers/auth/AuthReducer";
import { getTokenPayload } from "shared/utils/Utils";

function PaymentLandingGateway() {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useSelector((state) => ({
        token: state.auth.token,
    }));
    const tokenData = getTokenPayload(token);
    const dispatch = useDispatch();
    

    const [data, setData] = useState();
    const isOurAppWebView = () => {
        if(window.BRIDGE && window.BRIDGE.isOurAppWebView()){
            return window.BRIDGE.isOurAppWebView();
        }else{
            return false;
        }
    }
    const receiveToken = () => {
        if(window?.BRIDGE && window?.BRIDGE?.getToken?.()){
            
            dispatch(AuthReducer.actions.setToken(window.BRIDGE.getToken()));
        }else{
            return false;
        }
    }

    useEffect(() => {
        
        // alert(isOurAppWebView());
        const queryParams = new URLSearchParams(location.search);
        const dataParams = queryParams.get('data');
        const trxIdParams = queryParams.get('trxId');
        const trackIdParam = queryParams.get('trackId');
        const amountParam = queryParams.get('amount');
        const storeCodeParam = queryParams.get('storeCode');
        const deviceCodeParam = queryParams.get('deviceCode');
        

        setData({
            trxId: trxIdParams,
            trackId: trackIdParam,
            amount: amountParam,
            storeCode: storeCodeParam,
            machineData: dataParams,
            deviceCodeData: deviceCodeParam
        });
    }, [location]);

    useEffect(() => {
        if (data) {
            
            const isAppWebView = isOurAppWebView();
            const userAgent = navigator.userAgent.toLowerCase();
            const isIPhone = /iphone/i.test(userAgent);
            const isAndroid = /android/i.test(userAgent);
    


            // 신규로직
            if (tokenData) {
                // 토큰이 있는 경우 (사용자가 인증된 경우)
                if (isAppWebView) {
                    // 우리 앱의 웹뷰에서 인증된 사용자
                    // 결제 페이지로 이동
                    navigate(`/ws/${data.storeCode}/payment?data=${data.machineData}&trxId=${data.trxId}&trackId=${data.trackId}&amount=${data.amount}&storeCode=${id}&deviceCode=${data.deviceCodeData}`);
                } else {
                    // 우리 앱의 웹뷰가 아닌 경우
                    if (isIPhone) {
                        // iOS 브라우저
                        navigate(`/ws/${id}/landing`, { state: { data: data } });
                    } else if (isAndroid) {
                        // Android 브라우저
                        // window.location.href = `https://puffu.kr/ws/${id}/landing?data=${encodeURIComponent(JSON.stringify(data))}`;
                        navigate(`/ws/${id}/landing`, { state: { data: data } });
                    } else {
                        // 기타 플랫폼
                        navigate(`/ws/${id}/landing`, { state: { data: data } });
                    }
                }
            } else {

                // 토큰이 없는 경우 (사용자가 인증되지 않은 경우)
                if (isAppWebView) {
                    
                    receiveToken();
                    //이건 테스트용으로 해놓은거라 지워야함 ~! 
                    navigate(`/ws/${data.storeCode}/payment?data=${data.machineData}&trxId=${data.trxId}&trackId=${data.trackId}&amount=${data.amount}&storeCode=${id}&deviceCode=${data.deviceCodeData}`);
                    //이걸 이따가 활성화 시켜야함 
                    // navigate(`/login`);
                } else {
                    // 우리 앱의 웹뷰가 아닌 경우
                    if (isIPhone) {
                        // iOS 브라우저
                        navigate(`/ws/${id}/landing`, { state: { data: data } });
                    } else if (isAndroid) {
                        // Android 브라우저
                        // window.location.href = `https://puffu.kr/ws/${id}/landing?data=${encodeURIComponent(JSON.stringify(data))}`;
                        navigate(`/ws/${id}/landing`, { state: { data: data } });
                    } else {
                        // 기타 플랫폼
                        navigate(`/ws/${id}/landing`, { state: { data: data } });
                    }
                }
            }

        }
    }, [data]);


    return (
        <Container>

        </Container>
    );
}

export default PaymentLandingGateway;
