import { requester } from "App";
import CustomButton from "components/buttons/CustomButton";
import Container from "layouts/container/Container";
import FlexChild from "layouts/flex/FlexChild";
import VerticalFlex from "layouts/flex/VerticalFlex";
import { BrowserDetectContext } from "providers/BrowserEventProvider";
import { QRCodeSVG } from "qrcode.react";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function QrTest() {
    const { isMobile } = useContext(BrowserDetectContext);
    const [isMounted, setMounted] = useState(false);
    const [paymentResult, setPaymentResult] = useState();
    const [isButtonDisabled, setButtonDisabled] = useState(false); // 추가된 상태 변수
    const id = 5;
    const dispatch = useDispatch();
    const testOrderData = {
        "orderProducts": [
            {
                "id": "a5b06212-89d5-4065-b8d5-a9c8fed9183c",
                "vendorId": null,
                "categoryId": "6873ea91-b0ac-4fad-afee-fa3f13f41849",
                "brandId": null,
                "storeId": null,
                "productId": "05b7ecc6-3404-4d47-b8b8-7452c428b4ae",
                "machineId": null,
                "machineType": null,
                "vendorName": null,
                "productName": "아모스 딱풀",
                "locationNo": 0,
                "price": 1000,
                "supplyPrice": 700,
                "productImg": "",
                "vendingId": null,
                "quantity": 1,
                "radarImg": "",
                "bannerTextImg": "",
                "liquidImg": "",
                "productDetailImg": "PHA+JiN4YzU0NDsmI3hiYWE4OyYjeGMyYTQ7ICYjeGI1MzE7JiN4ZDQ4MDsgPC9wPg==",
                "productBgImg": ""
            }
        ],
        "amount": 1000,
        "userName": "",
        "storeCode": 5,
        "machineData": "W1swLDFdXQ==",
        "returnUrl": "https://puffuandroid.com/ws/5/mobile/main"
    }

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            TestOnClick();
        }
    }, [isMounted]);

    const PaymentQR = () => {
        
        const deviceCode = window.BRIDGE && window.BRIDGE.getDeviceCode ? window.BRIDGE.getDeviceCode() : "";
        const paymentHistoryId = paymentResult && paymentResult.paymentHistoryId;

        

        return (
            <QRCodeSVG
                // value={`https://puffuandroid.com/ws/5/payment?${param}&trxId=${trxId}&trackId=${trackId}&amount=${amount}&storeCode=${storeCode}&deviceCode=${deviceCode}`}
                // value={`http://59.26.140.16:13000/ws/5/landingGateWay?${param}&trxId=${trxId}&trackId=${trackId}&amount=${amount}&storeCode=${storeCode}&deviceCode=${deviceCode}`}
                // value={`http://59.26.140.16:13000/ws/5/paymentHistory?id=${paymentHistoryId}`}
                value={`https://puffu.kr/ws/5/paymentHistory?id=${paymentHistoryId}`}
                // value={`http://59.26.140.16:13000/ws/5/payment?${param}&trxId=${trxId}&trackId=${trackId}&amount=${amount}&storeCode=${storeCode}&deviceCode=${deviceCode}`}
                size={"20vw"}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
            />
        );
    };


    const TestOnClick = () => {
        // 버튼을 비활성화
        setButtonDisabled(true);
        requester.testPaymentRequest(testOrderData, (result) => {
            if (result.code === 0) {
                let ready = result.data;
                requester.testPayment(ready, (result) => {
                    if (result.code === 0) {
                        setPaymentResult(result.data);
                        const jsonString = JSON.stringify(result.data);
                        if (window.BRIDGE && window.BRIDGE.sendPaymentResult) {
                            window.BRIDGE.sendPaymentResult(jsonString);
                        }
                    }
                    // 5초 후에 버튼을 다시 활성화
                    setTimeout(() => setButtonDisabled(false), 5000);
                });
            } else {
                toast.error("문제가 발생했습니다 관리자에게 문의하세요", {
                    autoClose: 1000,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                // 에러 발생 시에도 버튼을 다시 활성화
                setButtonDisabled(false);
            }
        });
    };

    return (
        <Container>
            <Container maxWidth={1200}>
                <VerticalFlex gap={30}>
                    <FlexChild>
                        <PaymentQR />
                    </FlexChild>
                    <FlexChild>
                        <CustomButton
                            text={"REFRESH"}
                            onClick={() => TestOnClick()}
                            disabled={isButtonDisabled} // 버튼 비활성화 속성 추가
                        />
                    </FlexChild>
                </VerticalFlex>
            </Container>
        </Container>
    );
}

export default QrTest;
